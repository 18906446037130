<template>
  <Range
    v-model="model"
    :disabled="disabled"
    :min="min"
    :max="max"
    :step="step"
    :label="label"
    :field-wrapper-attrs="{ class: 'tw-mt-1' }"
  >
    <template #output>
      <FormField
        v-model.number="input"
        :disabled="disabled"
        type="number"
        :min="min"
        :max="max"
        :step="step"
        size="small"
        class="tw-w-14"
        :body-attrs="{ class: 'tw-min-h-5' }"
        :field-attrs="{ class: 'tw-text-sm tw-text-right tw-px-2' }"
        @update:modelValue="setModelFromInput(false)"
        @blur="setModelFromInput"
      />
    </template>
  </Range>
</template>

<script setup>
import roundToStep from '@/utils/roundToSteps';
import { FormField, Range } from '@slideslive/fuse-kit/vue';
import { onMounted, ref } from 'vue';

const props = defineProps({
  min: {
    type: Number,
    default: 0,
  },
  max: {
    type: Number,
    default: 100,
  },
  step: {
    type: Number,
    default: 1,
  },
  label: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const input = ref(0);
const model = defineModel({
  required: true,
  set(value) {
    if (+value !== input.value) {
      input.value = +value;
    }

    return value;
  },
});

const setModelFromInput = (shouldRoundToStep = true) => {
  if (input.value === '') return;

  let newValue = input.value;

  if (shouldRoundToStep) {
    newValue = roundToStep(input.value, props.step);
  }

  newValue = Math.max(newValue, props.min);
  newValue = Math.min(newValue, props.max);

  model.value = newValue;
};

onMounted(() => {
  input.value = model.value;
});
</script>
