<template>
  <ControlSection>
    <FormTag
      v-model="model"
      :disabled="locked"
      type="radio"
      name="controlAction"
      value="draw_tz"
      :label="$t('control_modes.tracking_zones.draw')"
      class="tw-whitespace-nowrap"
    />

    <div class="tw-flex tw-items-center tw-gap-x-2">
      <Dropdown
        v-for="tzBorder in trackingZonesBorders"
        :key="tzBorder.type"
        :overlay="false"
        :disabled="bordersDisabled"
        :trigger-attrs="{
          iconButton: true,
          reset: true,
          icon: `border-${tzBorder.type}`,
          color: 'semiTransparentWhite',
          shape: 'rounded',
          size: 'large',
          ariaLabel: $t(`control_modes.tracking_zones.${tzBorder.type}`),
        }"
        :menu-attrs="{ class: 'tw-min-w-0' }"
      >
        <template #menu>
          <CircularButton
            v-for="direction in tzBorder.diractions"
            :key="direction"
            :icon="`arrow-${direction}`"
            color="transparentWhite"
            size="small"
            shape="rounded"
            :disabled="bordersDisabled"
            :aria-label="$t(`control_modes.tracking_zones.move_${direction}`)"
            @click="moveTrackingZone(tzBorder.type, direction)"
          />
        </template>
      </Dropdown>

      <CircularButton
        icon="trash"
        color="semiTransparentWhite"
        shape="rounded"
        size="large"
        :disabled="locked"
        :aria-label="$t('control_modes.tracking_zones.delete')"
        @click="deleteTrackingZone"
      />
    </div>
  </ControlSection>
</template>

<script setup>
import useAppStore from '@/stores/app';
import useDataStore from '@/stores/data';
import { CircularButton, Dropdown, FormTag } from '@slideslive/fuse-kit/vue';
import { storeToRefs } from 'pinia';
import { computed, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';

import ControlSection from './ControlSection.vue';

const { t } = useI18n();
const appStore = useAppStore();
const { locked } = storeToRefs(appStore);
const dataStore = useDataStore();
const { zones } = toRefs(dataStore.state);
const model = defineModel();

const bordersDisabled = computed(() => !zones.value || zones.value.length === 0 || locked.value);

const trackingZonesBorders = [
  {
    type: 'top',
    label: t('control_modes.tracking_zones.top'),
    diractions: ['up', 'down'],
  },
  {
    type: 'bottom',
    label: t('control_modes.tracking_zones.bottom'),
    diractions: ['up', 'down'],
  },
  {
    type: 'left',
    label: t('control_modes.tracking_zones.left'),
    diractions: ['left', 'right'],
  },
  {
    type: 'right',
    label: t('control_modes.tracking_zones.right'),
    diractions: ['left', 'right'],
  },
];

const moveTrackingZone = (border, direction) => {
  console.log('move tracking zone', border, direction);
};

const deleteTrackingZone = () => {
  console.log('delete tracking zone');
};
</script>
