<template>
  <div class="tw-flex tw-w-full tw-max-w-213 tw-gap-x-5">
    <div class="tw-flex tw-flex-1 tw-flex-col tw-items-end tw-space-y-1">
      <div class="tw-aspect-video tw-w-full tw-bg-red"></div>
      <ResourcesUsage />
    </div>

    <Range
      v-model="safeZoneZoom"
      :disabled="locked"
      :min="safeZoneZoomState.min"
      :max="safeZoneZoomState.max"
      :step="safeZoneZoomState.step"
      vertical
      hide-axis-labels
      :class="['tw-flex', 'tw-items-stretch', controlMode === 'tracker' && 'tw-invisible']"
    />
  </div>
</template>

<script setup>
import useAppStore from '@/stores/app';
import useDataStore from '@/stores/data';
import { Range } from '@slideslive/fuse-kit/vue';
import { storeToRefs } from 'pinia';
import { ref, toRefs } from 'vue';

import ResourcesUsage from './ResourcesUsage.vue';

const appStore = useAppStore();
const { locked } = storeToRefs(appStore);
const dataStore = useDataStore();
const { controller, movement } = toRefs(dataStore.state);
const { control_mode: controlMode } = toRefs(controller.value);
const { safe_zone_size_zoom: safeZoneZoomState } = toRefs(movement?.value);

const safeZoneZoom = ref(safeZoneZoomState.value.value);
</script>
