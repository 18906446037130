<template>
  <ControlSection>
    <FormTag
      v-model="model"
      :disabled="locked"
      type="radio"
      name="controlAction"
      value="push_focus"
      :label="$t('control_modes.focus.push_focus')"
      class="tw-whitespace-nowrap"
    />

    <div class="tw-flex tw-items-center tw-gap-x-5">
      <Radio
        v-for="mode in focusModes"
        :key="mode.value"
        v-model="focusMode"
        name="focusMode"
        :disabled="locked"
        :id-prefix="mode.value"
        :value="mode.value"
        :right-label="t(`control_modes.focus.${mode.value.toLowerCase()}`)"
      />

      <div v-if="controlMode === 'watcher'" class="tw-flex tw-items-center tw-gap-x-2">
        <Button :disabled="locked" color="semiTransparentWhite">
          {{ $t('control_modes.focus.near') }}
        </Button>

        <Button :disabled="locked" color="semiTransparentWhite">
          {{ $t('control_modes.focus.far') }}
        </Button>
      </div>
    </div>
  </ControlSection>
</template>

<script setup>
import useAppStore from '@/stores/app';
import useDataStore from '@/stores/data';
import { Button, FormTag, Radio } from '@slideslive/fuse-kit/vue';
import { storeToRefs } from 'pinia';
import { ref, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';

import ControlSection from './ControlSection.vue';

defineProps({
  controlMode: {
    type: String,
    required: true,
  },
});

const model = defineModel();
const { t } = useI18n();
const appStore = useAppStore();
const { locked } = storeToRefs(appStore);
const dataStore = useDataStore();
const { focus } = toRefs(dataStore.state);
const { focus_mode: focusModeState } = toRefs(focus.value);

const focusModes = [
  {
    value: 'AF',
    label: t('control_modes.focus.af'),
  },
  {
    value: 'NF',
    label: t('control_modes.focus.nf'),
  },
  {
    value: 'MF',
    label: t('control_modes.focus.mf'),
  },
];

const focusMode = ref(focusModeState.value);
</script>
