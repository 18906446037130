<template>
  <div v-if="isPending || isLoading" class="tw-flex tw-h-full tw-items-center tw-justify-center">
    <Spinner size="large" />
  </div>

  <div v-else class="tw-flex tw-min-h-full tw-flex-col tw-px-5">
    <Header />

    <main class="tw-flex tw-flex-1 tw-flex-col">
      <div class="tw-flex tw-flex-wrap tw-items-start tw-gap-5 tw-border-b-2 tw-border-purple/25 tw-py-4">
        <SettingsTabs />

        <div class="tw-w-px tw-shrink-0 tw-self-stretch tw-bg-purple/25"></div>

        <SpeakersPresetsTabs />

        <div class="tw-w-px tw-shrink-0 tw-self-stretch tw-bg-purple/25"></div>

        <Multiview />
      </div>

      <div class="tw-flex tw-flex-1 tw-items-start tw-gap-5 tw-py-5">
        <Preview />

        <ControlModes />
      </div>
    </main>
  </div>

  <div v-if="remoteStateDisabled">Remote state disabled</div>
  <div v-if="error">Error: {{ error.message }}</div>
</template>

<script setup>
import api from '@/api';
import Header from '@/components/Header.vue';
import Multiview from '@/components/Multiview.vue';
import Preview from '@/components/Preview.vue';
import ControlModes from '@/components/controlModes/ControlModes.vue';
import SettingsTabs from '@/components/settingsTabs/SettingsTabs.vue';
import SpeakersPresetsTabs from '@/components/speakersPresetsTabs/SpeakersPresetsTabs.vue';
import usePolling from '@/composables/polling';
import useDataStore from '@/stores/data';
import { isVariableDefinedNotNull } from '@slideslive/fuse-kit/utils';
import { Spinner } from '@slideslive/fuse-kit/vue';
import { computed, onMounted, toRefs, watch } from 'vue';

const dataStore = useDataStore();
const { setIsLoading, setIsFetching, setIsError, setError, updateRemoteState, setSessionId } = dataStore;
const { sessionId, remoteStateDisabled } = toRefs(dataStore);
const remoteStatePollingEnabled = computed(
  () => isVariableDefinedNotNull(sessionId.value) && !remoteStateDisabled.value,
);

const openSession = async (retry = 1) => {
  if (retry > 3) {
    throw new Error('Failed to open session');
  }

  const { success, data: newSessionId } = await api.open();

  if (success) {
    setSessionId(newSessionId);
  } else {
    setTimeout(() => {
      openSession(retry + 1);
    }, retry * 250);
  }
};

const {
  isPending,
  isLoading,
  isFetching,
  isError,
  error,
  data: remoteState,
} = usePolling({
  queryFn: api.state,
  enabled: remoteStatePollingEnabled,
  pollingInterval: 1000,
});

watch([isPending, isLoading], () => setIsLoading(isPending.value || isLoading.value));
watch(isFetching, setIsFetching);
watch(isError, setIsError);
watch(error, setError);
watch(remoteState, updateRemoteState);

onMounted(() => {
  openSession();
});
</script>
