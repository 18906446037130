import { useEventListener } from '@vueuse/core';
import axios from 'axios';
import { onUnmounted, ref, unref, watch } from 'vue';

function usePolling({
  enabled = ref(true),
  pollingInterval = ref(1000),
  refetchOnWindowFocus = ref(true),
  retryCount = ref(5),

  queryFn,
}) {
  const isPending = ref(true);
  const isLoading = ref(false);
  const isFetching = ref(false);
  const isError = ref(false);
  const error = ref(null);
  const data = ref(null);

  let timeoutId = null;
  let abortController = null;
  let currentRetries = 0;

  const clearPolling = () => {
    if (timeoutId !== null) {
      clearTimeout(timeoutId);
      timeoutId = null;
    }

    if (abortController) {
      abortController.abort();
      abortController = null;
    }
  };

  const executePoll = async () => {
    if (isFetching.value || !unref(enabled)) return;

    try {
      abortController = new AbortController();
      isFetching.value = true;

      if (data.value === null) {
        isLoading.value = true;
      }

      const { success, data: newData } = await queryFn(null, { abortController });

      if (!success) throw new Error('Failed to fetch data');

      data.value = newData;
      error.value = null;
      isError.value = false;
      currentRetries = 0;

      timeoutId = setTimeout(executePoll, unref(pollingInterval));
    } catch (err) {
      if (axios.isCancel(err)) return;

      error.value = err;
      isError.value = true;

      if (currentRetries < unref(retryCount)) {
        currentRetries++;
        timeoutId = setTimeout(executePoll, unref(pollingInterval));

        return;
      }
    } finally {
      isPending.value = false;
      isFetching.value = false;
      isLoading.value = false;
    }
  };

  watch(
    enabled,
    (newValue) => {
      clearPolling();

      if (newValue) {
        executePoll();
      }
    },
    { immediate: true },
  );

  useEventListener(document, 'visibilitychange', () => {
    if (!unref(refetchOnWindowFocus) || !unref(enabled)) return;

    if (document.visibilityState === 'hidden') {
      clearPolling();
    } else if (document.visibilityState === 'visible') {
      executePoll();
    }
  });

  onUnmounted(() => {
    clearPolling();
  });

  return {
    isPending,
    isLoading,
    isFetching,
    isError,
    error,
    data,
  };
}

export default usePolling;
