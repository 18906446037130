<template>
  <component :is="tag" class="tw-relative tw-aspect-[58/72] tw-bg-red">
    <div
      class="tw-absolute tw-left-0 tw-top-0 tw-flex tw-w-full tw-items-center tw-justify-between tw-bg-dark/50 tw-p-0.5"
    >
      <Text size="xsmall">[{{ speaker.speaker_id }}]</Text>

      <CircularButton
        :disabled="deleteDisabled || locked"
        icon="close"
        size="xsmall"
        color="transparentWhite"
        class="tw--m-0.5 tw-size-4"
        :icon-attrs="{ class: 'tw-size-3' }"
        @click="triggerDelete"
      />
    </div>
  </component>
</template>

<script setup>
import useAppStore from '@/stores/app';
import { CircularButton, Text } from '@slideslive/fuse-kit/vue';
import { storeToRefs } from 'pinia';

const props = defineProps({
  tag: {
    type: String,
    default: 'div',
  },
  deleteDisabled: {
    type: Boolean,
    default: false,
  },
  speaker: {
    type: Object,
    required: true,
  },
  onDelete: {
    type: Function,
    default: undefined,
  },
});

const emit = defineEmits(['delete']);

const appStore = useAppStore();
const { locked } = storeToRefs(appStore);

const triggerDelete = () => {
  emit('delete', props.speaker.speaker_id);
};
</script>
