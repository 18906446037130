<template>
  <Text tag="ul" class="tw-flex tw-flex-wrap tw-items-center tw-gap-x-1">
    <li>
      {{ $t('resources_usage.pid') }}: <strong class="tw-font-black">{{ pid }}</strong>
    </li>

    <li class="tw-w-0.5 tw-self-stretch tw-bg-purple/25"></li>

    <li>
      {{ $t('resources_usage.cpu') }}: <strong class="tw-font-black">{{ cpuUsage }}%</strong>
    </li>

    <li class="tw-w-0.5 tw-self-stretch tw-bg-purple/25"></li>

    <li>
      {{ $t('resources_usage.gpu') }}: <strong class="tw-font-black">{{ gpuUsage }}%</strong>
    </li>

    <li class="tw-w-0.5 tw-self-stretch tw-bg-purple/25"></li>

    <li>
      {{ $t('resources_usage.ram') }}: <strong class="tw-font-black">{{ memoryUsage }}%</strong>
    </li>

    <li class="tw-w-0.5 tw-self-stretch tw-bg-purple/25"></li>

    <li>
      {{ $t('resources_usage.disk') }}: <strong class="tw-font-black">{{ diskUsage }}%</strong>
    </li>
  </Text>
</template>

<script setup>
import useDataStore from '@/stores/data';
import { Text } from '@slideslive/fuse-kit/vue';
import { toRefs } from 'vue';

const dataStore = useDataStore();
const { pid } = toRefs(dataStore.state.app);
const {
  cpu_usage: cpuUsage,
  disk_usage: diskUsage,
  gpu_usage: gpuUsage,
  memory_usage: memoryUsage,
} = toRefs(dataStore.state.stats);
</script>
