<template>
  <TabPanel v-bind="$attrs" class="tw-flex tw-h-full tw-flex-col tw-justify-between">
    <ol class="tw-flex tw-flex-wrap tw-gap-4">
      <li v-for="{ preset_id, name } in presets" :key="preset_id">
        <Button :disabled="locked" size="small" color="transparentWhite" outlined>{{ name }}</Button>
      </li>
    </ol>

    <div class="tw-mt-1.5 tw-flex tw-flex-col">
      <Checkbox
        v-model="writePreset"
        :disabled="locked"
        type="switch"
        :right-label="$t('speakers_presets_tabs.presets.write_preset')"
      />

      <Text size="large" class="tw-text-right">
        <Link tag="button" scheme="underline" @click="openSettings">
          {{ $t('settings') }}
        </Link>
      </Text>
    </div>
  </TabPanel>

  <PresetsModal v-model="settingsOpen" />
</template>

<script setup>
import useAppStore from '@/stores/app';
import useDataStore from '@/stores/data';
import { Button, Checkbox, Link, TabPanel, Text } from '@slideslive/fuse-kit/vue';
import { storeToRefs } from 'pinia';
import { ref, toRefs } from 'vue';

import PresetsModal from './PresetsModal.vue';

const appStore = useAppStore();
const { locked } = storeToRefs(appStore);
const dataStore = useDataStore();
const { presets } = toRefs(dataStore.state);
const writePreset = ref(false);
const settingsOpen = ref(false);

const openSettings = () => {
  settingsOpen.value = true;
};
</script>
