<template>
  <div class="tw-space-y-5">
    <Heading level="h3" level-class="h4">{{ $t('settings_modal.other.title') }}</Heading>

    <div class="tw-flex tw-flex-wrap tw-items-start tw-gap-5">
      <div class="tw-flex tw-flex-wrap tw-items-start tw-gap-2">
        <Button color="semiTransparentWhite" tag="a" href="/files/camera_config" target="_blank" download>
          <Icon name="download" class="tw-mr-2 tw-size-6 tw-fill-current" />
          {{ $t('settings_modal.other.download_main_cam_settings') }}
        </Button>

        <Button color="semiTransparentWhite" :disabled="locked" @click="api.resetCamPlatform">
          {{ $t('settings_modal.other.pan_tilt_recalibration') }}
        </Button>
      </div>

      <FormField
        :value="mainCamSrtStreamPathState"
        readonly
        :label="$t('settings_modal.other.srt_stream_from_main_camera.label')"
        class="tw-w-full sm:tw-w-[calc(100%/2-0.625rem)] md:tw-w-[calc(100%/3-0.625rem)] lg:tw-w-[calc(100%/4-0.625rem)]"
      />
    </div>
  </div>
</template>

<script setup>
import api from '@/api';
import useAppStore from '@/stores/app';
import useDataStore from '@/stores/data';
import { Button, FormField, Heading, Icon } from '@slideslive/fuse-kit/vue';
import { toRefs } from 'vue';

const appStore = useAppStore();
const { locked } = toRefs(appStore);
const dataStore = useDataStore();
const { app } = toRefs(dataStore.state);
const { main_cam_srt_stream_path: mainCamSrtStreamPathState } = toRefs(app.value);
</script>
