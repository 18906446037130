<template>
  <TabPanel class="tw-grid tw-grid-cols-2 tw-gap-x-10 tw-gap-y-3">
    <SettingsRange
      v-model="safeZonePan"
      :disabled="locked"
      :min="safeZonePanState.min"
      :max="safeZonePanState.max"
      :step="safeZonePanState.step"
      :label="$t('settings_tabs.main.safe_zone_pan')"
    />

    <SettingsRange
      v-model="shotScale"
      :disabled="locked"
      :min="shotScaleState.min"
      :max="shotScaleState.max"
      :step="shotScaleState.step"
      :label="$t('settings_tabs.main.shot_scale')"
    />

    <SettingsRange
      v-model="safeZoneTilt"
      :disabled="locked"
      :min="safeZoneTiltState.min"
      :max="safeZoneTiltState.max"
      :step="safeZoneTiltState.step"
      :label="$t('settings_tabs.main.safe_zone_tilt')"
    />

    <SettingsRange
      v-model="leadRoomFactor"
      :disabled="locked"
      :min="leadRoomFactorState.min"
      :max="leadRoomFactorState.max"
      :step="leadRoomFactorState.step"
      :label="$t('settings_tabs.main.lead_room_factor')"
    />

    <SettingsRange
      v-model="safeZoneZoom"
      :disabled="locked"
      :min="safeZoneZoomState.min"
      :max="safeZoneZoomState.max"
      :step="safeZoneZoomState.step"
      :label="$t('settings_tabs.main.safe_zone_zoom')"
    />
  </TabPanel>
</template>

<script setup>
import useAppStore from '@/stores/app';
import useDataStore from '@/stores/data';
import { TabPanel } from '@slideslive/fuse-kit/vue';
import { storeToRefs } from 'pinia';
import { ref, toRefs } from 'vue';

import SettingsRange from './SettingsRange.vue';

const appStore = useAppStore();
const { locked } = storeToRefs(appStore);
const dataStore = useDataStore();
const { movement, composition } = toRefs(dataStore.state);
const {
  safe_zone_size_pan: safeZonePanState,
  safe_zone_size_tilt: safeZoneTiltState,
  safe_zone_size_zoom: safeZoneZoomState,
} = toRefs(movement.value);
const { shot_scale: shotScaleState, lead_room_factor: leadRoomFactorState } = toRefs(composition.value);

const safeZonePan = ref(safeZonePanState.value.value);
const safeZoneTilt = ref(safeZoneTiltState.value.value);
const safeZoneZoom = ref(safeZoneZoomState.value.value);
const shotScale = ref(shotScaleState.value.value);
const leadRoomFactor = ref(leadRoomFactorState.value.value);
</script>
