import getNestedObject from '@/utils/getNestedObject';
import { deepMerge, generateRandomId, isArray, isObject, toCamelCase } from '@slideslive/fuse-kit/utils';

import DataFetcher from './DataFetcher';

export default class MockDataFetcher extends DataFetcher {
  constructor() {
    super();
    this.mockData = this.#getDefaultMockData();
  }

  async #simulateRequest() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, this.#mockRequestDelay);
    });
  }

  #generateResponse(data, success = true) {
    return { success, data: data ? deepMerge({}, data) : data };
  }

  async get(path) {
    await this.#simulateRequest();

    switch (path) {
      case '/open':
        return this.#generateResponse(generateRandomId(20));
      case '/state':
        return this.#generateResponse(this.mockData);
      default:
        return this.#generateResponse(null, false);
    }
  }

  async post(_path, data, { statePath = null } = {}) {
    await this.#simulateRequest();

    if (statePath) {
      const { object, key } = getNestedObject(this.mockData.data, statePath);

      object[key] = data;
    } else if (isObject(data)) {
      this.mockData.data = deepMerge(this.mockData.data, data, { replaceArrays: true });
    }

    return this.#generateResponse(this.mockData);
  }

  async patch(_path, data, { statePath = null } = {}) {
    await this.#simulateRequest();

    if (statePath) {
      const { object, key } = getNestedObject(this.mockData.data, statePath);

      object[key] = data;
    } else if (isObject(data)) {
      this.mockData.data = deepMerge(this.mockData.data, data, { replaceArrays: true });
    }

    return this.#generateResponse();
  }

  async put() {
    await this.#simulateRequest();

    return this.#generateResponse(this.mockData);
  }

  async delete(_path, _data, { key = null, statePath = null, ...options } = {}) {
    await this.#simulateRequest();

    const { object, key: objectKey } = getNestedObject(this.mockData.data, statePath);

    if (!key) {
      if (isArray(object[objectKey])) {
        object[objectKey] = [];
      } else {
        delete object[objectKey];
      }
    } else {
      object[objectKey] = object[objectKey].filter((item) => item[key] !== options[toCamelCase(key)]);
    }

    return this.#generateResponse(this.mockData);
  }

  get #mockRequestDelay() {
    return Math.random() * 250 + 250;
  }

  #getDefaultMockData() {
    return {
      success: true,
      message: 'State retrieved successfully',
      session_id: 0,
      errors: null,
      error_code: null,
      data: {
        app: {
          debug_mode: false,
          track_name: 'Strauss 1-3, Martin Mondek',
          computer_name: 'c36177f2efb9',
          host_ip: '10.210.19.2',
          main_cam_srt_stream_path: 'srt://10.210.19.3:5100',
          main_cam_url: 'http://10.210.19.3',
          nate_number: 20,
          nate_runner_url: 'http://10.210.19.2',
          nate_version: '1.11.0 - dev',
          pid: 7,
          station_name: 'NATE #19',
          station_subnet: '10.210.19.0/24',
          wide_cam_url: 'http://10.210.19.4',
        },
        composition: {
          lead_room_factor: {
            value: 0.68,
            min: 0,
            max: 1,
            step: 0.01,
          },
          pan_offset: {
            value: 0,
            min: null,
            max: null,
            step: 0.005,
          },
          shot_scale: {
            value: 3.7,
            min: 1,
            max: 6,
            step: 0.1,
          },
          sitting_mode: false,
          tilt_offset: {
            value: 0,
            min: null,
            max: null,
            step: 0.005,
          },
        },
        controller: {
          auto_speaker_removal: true,
          auto_speaker_selection: true,
          auto_panel_discussion: false,
          control_mode: 'tracker',
          main_audio_channel: 'left',
          output_gui_video_encoder: {
            options: ['jpg', 'webp'],
            value: 'webp',
          },
          output_gui_video_fps: {
            value: 12,
            min: 0.2,
            max: 25,
            step: 1,
          },
          output_gui_video_quality: {
            value: 60,
            min: 1,
            max: 90,
            step: 1,
          },
          show_calibration_grid: false,
          single_speaker_mode: true,
          strict_mode: false,
        },
        focus: {
          focus_mode: 'MF',
        },
        cameras: {
          main: {
            audio_input_mode: {
              options: [
                'MIC Terminal/LINE',
                'MIC Terminal/MIC',
                'MIC Terminal/MIC (MIC Power On)',
                'INPUT Terminal/LINE',
                'INPUT Terminal/MIC',
                'INPUT Terminal/MIC+48V',
              ],
              selected_index: 1,
            },
            audio_input_volume: {
              value: 50,
              min: 1,
              max: 100,
              step: 1,
            },
            capture_delay: {
              value: 50,
              min: -100,
              max: 250,
              step: 5,
            },
          },
        },
        movement: {
          manual_move_pan_tilt_speed: {
            value: 10,
            min: 1,
            max: 10,
            step: 1,
          },
          manual_move_zoom_speed: {
            value: 10,
            min: 1,
            max: 10,
            step: 1,
          },
          ramp: {
            value: 1,
            min: 0,
            max: 2,
            step: 1,
          },
          safe_zone_size_pan: {
            value: 0.17,
            min: 0,
            max: 1,
            step: 0.01,
          },
          safe_zone_size_tilt: {
            value: 0.17,
            min: 0,
            max: 1,
            step: 0.01,
          },
          safe_zone_size_zoom: {
            value: 0.17,
            min: 0,
            max: 1,
            step: 0.01,
          },
          top_padding: {
            value: 0,
            min: 0,
            max: 0.3,
            step: 0.1,
          },
        },
        router: {
          downlink_speed: 1000,
          internet_connected: true,
          router_ip: '10.210.19.1',
          uplink_speed: 1000,
          wan_interface: 'ethernet',
        },
        stats: {
          cpu_usage: 35.2,
          disk_usage: 68.3,
          gpu_usage: 45.1,
          memory_usage: 70.2,
        },
        switcher: {
          vmix_ip_port: '10.210.19.5:8088',
          vmix_main_cam_label: 'CAM1',
          vmix_wide_cam_label: 'WIDESHOT',
          vmix_nate_cam_label: 'NATE_CAM',
          control_vmix: false,
          selected_camera: {
            options: [0, 1],
            value: 1,
          },
        },
        recorders_config: {
          device_types: ['ATEM', 'Z CAM', 'KILOVIEW', 'HyperDeck'],
          recorder_indicator_on: true,
        },
        presets: [
          {
            preset_id: 1,
            name: 'P1',
          },
          {
            preset_id: 2,
            name: 'P2',
          },
          {
            preset_id: 3,
            name: 'P3',
          },
          {
            preset_id: 4,
            name: 'P4',
          },
          {
            preset_id: 5,
            name: 'P5',
          },
          {
            preset_id: 6,
            name: 'P6',
          },
          {
            preset_id: 7,
            name: 'P7',
          },
          {
            preset_id: 8,
            name: 'Tech Table',
          },
          {
            preset_id: 9,
            name: 'Home',
          },
        ],
        recorders: [
          {
            device_type: 'ATEM',
            recorder_name: 'ATEM: MASTER',
            ip_address: '10.210.19.5',
            recorder_id: 0,
            master: true,
            removable: false,
            message: 'ATEM: recording, remains 18:01:58',
            status: 'recording',
            error: null,
            interface_url: null,
          },
          {
            device_type: 'Z CAM',
            recorder_name: 'Z CAM: WIDE CAM',
            ip_address: '10.210.19.4',
            recorder_id: 1,
            master: false,
            removable: false,
            message: 'Z CAM: stopped, remains 00:00:00, 1080p25, 57 °C',
            status: 'stand-by',
            error: 'No card',
            interface_url: 'http://10.210.19.4',
          },
          {
            device_type: 'KILOVIEW',
            recorder_name: 'KILOVIEW: MAIN CAM',
            ip_address: '10.210.19.6',
            recorder_id: 2,
            master: false,
            removable: false,
            message: null,
            status: 'offline',
            error: "Can't connect to the device, check the IP.",
            interface_url: 'http://10.210.19.6',
          },
          {
            device_type: 'HyperDeck',
            recorder_name: 'CAM1 backup',
            ip_address: '10.210.19.10',
            recorder_id: 3,
            master: false,
            removable: true,
            message: null,
            status: 'stand-by',
            error: null,
            interface_url: null,
          },
        ],
        speakers: [
          {
            speaker_id: 3,
            speaking: false,
            ignored: false,
            detection_age_seconds: 5471.07,
            image_url: '/speakers/3/faces?hash=fsdf5df',
            x_pos: 258,
          },
          {
            speaker_id: 964,
            speaking: false,
            ignored: false,
            detection_age_seconds: 2609,
            image_url: '/speakers/964/faces?hash=5df54ssd',
            x_pos: -450,
          },
          {
            speaker_id: 978,
            speaking: false,
            ignored: false,
            detection_age_seconds: 2083.2,
            image_url: '/speakers/978/faces?hash=fsdf5df',
            x_pos: 125,
          },
          {
            speaker_id: 1023,
            speaking: true,
            ignored: false,
            detection_age_seconds: 50.12,
            image_url: '/speakers/1023/faces?hash=7f8g9h',
            x_pos: -300,
          },
          {
            speaker_id: 1370,
            speaking: false,
            ignored: false,
            detection_age_seconds: 5468.54,
            image_url: '/speakers/1370/faces?hash=fsdf5df',
            x_pos: 980,
          },
          {
            speaker_id: 2110,
            speaking: false,
            ignored: true,
            detection_age_seconds: 7102.22,
            image_url: '/speakers/2110/faces?hash=q1r2s3',
            x_pos: -750,
          },
          {
            speaker_id: 3585,
            speaking: false,
            ignored: false,
            detection_age_seconds: 319.46,
            image_url: '/speakers/3585/faces?hash=5df54ssd',
            x_pos: 560,
          },
          {
            speaker_id: 3751,
            speaking: false,
            ignored: false,
            detection_age_seconds: 15.67,
            image_url: '/speakers/3751/faces?hash=t4u5v6',
            x_pos: -220,
          },
          {
            speaker_id: 4197,
            speaking: false,
            ignored: false,
            detection_age_seconds: 894.13,
            image_url: '/speakers/4197/faces?hash=fsdf5df',
            x_pos: 675,
          },
          {
            speaker_id: 4488,
            speaking: false,
            ignored: false,
            detection_age_seconds: 0.19,
            image_url: '/speakers/4488/faces?hash=5df54ssd',
            x_pos: -100,
          },
          {
            speaker_id: 5254,
            speaking: true,
            ignored: false,
            detection_age_seconds: 12.35,
            image_url: '/speakers/5254/faces?hash=a1b2c3',
            x_pos: 850,
          },
          {
            speaker_id: 5490,
            speaking: true,
            ignored: false,
            detection_age_seconds: 145.32,
            image_url: '/speakers/5490/faces?hash=n7o8p9',
            x_pos: 100,
          },
          {
            speaker_id: 6521,
            speaking: false,
            ignored: true,
            detection_age_seconds: 4002.76,
            image_url: '/speakers/6521/faces?hash=ffg6h7',
            x_pos: -550,
          },
          {
            speaker_id: 6701,
            speaking: false,
            ignored: true,
            detection_age_seconds: 4321.56,
            image_url: '/speakers/6701/faces?hash=j1k2l3',
            x_pos: 450,
          },
          {
            speaker_id: 7459,
            speaking: false,
            ignored: false,
            detection_age_seconds: 3654.23,
            image_url: '/speakers/7459/faces?hash=3x4y5z',
            x_pos: -900,
          },
          {
            speaker_id: 8812,
            speaking: false,
            ignored: false,
            detection_age_seconds: 99.87,
            image_url: '/speakers/8812/faces?hash=8h9j0k',
            x_pos: 0,
          },
          {
            speaker_id: 8932,
            speaking: false,
            ignored: false,
            detection_age_seconds: 278.98,
            image_url: '/speakers/8932/faces?hash=l4m5n6',
            x_pos: -330,
          },
        ],
        zones: [
          {
            zone_id: 1,
            name: 'Zone A',
            mode: 'tracking',
            left_top: {
              x: 0,
              y: 0,
            },
            left_bottom: {
              x: 0,
              y: 0,
            },
            right_top: {
              x: 0,
              y: 0,
            },
            right_bottom: {
              x: 0,
              y: 0,
            },
          },
        ],
      },
    };
  }
}
