<template>
  <Teleport to="body">
    <Modal ref="modal" v-model="show" @opening="reset" @close="reset">
      <ModalContent size="large">
        <template #header>
          <Heading level="h2" level-class="h3">{{ $t('presets_modal.title') }}</Heading>
        </template>

        <template #footer="{ reject }">
          <Button color="transparent" outlined @click="reject">{{ $t('cancel') }}</Button>
          <Button :disabled="locked" type="submit" form="presets-form">{{ $t('save') }}</Button>
        </template>

        <Form id="presets-form" class="tw-grid tw-grid-cols-1 tw-gap-5 sm:tw-grid-cols-2" @submit="save">
          <PresetEditItem v-for="(preset, index) in presetsState" :key="preset.preset_id" ref="items" :preset :index />
        </Form>
      </ModalContent>
    </Modal>
  </Teleport>
</template>

<script setup>
import api from '@/api';
import useDraftState from '@/composables/draftState';
import useAppStore from '@/stores/app';
import useDataStore from '@/stores/data';
import { deepEqual } from '@slideslive/fuse-kit/utils';
import { Button, Form, Heading, Modal, ModalContent } from '@slideslive/fuse-kit/vue';
import { ref, toRefs, useTemplateRef, watch } from 'vue';

import PresetEditItem from './PresetEditItem.vue';

const appStore = useAppStore();
const { locked } = toRefs(appStore);
const dataStore = useDataStore();
const { presets: presetsState } = toRefs(dataStore.state);

const show = defineModel();
const modal = ref(null);
const itemRefs = useTemplateRef('items');

const { trigger: setState } = useDraftState(api.patchState, { statePathToClear: ['presets'] });

const save = async () => {
  const presetsData = itemRefs.value.map((itemRef) => itemRef.getData());
  const somethingChanged = presetsData.some((preset) => {
    const originalPreset = presetsState.value.find(({ preset_id: presetId }) => presetId === preset.preset_id);

    return !originalPreset || originalPreset.name !== preset.name;
  });

  if (somethingChanged) {
    const statePatch = { presets: presetsData };

    setState(statePatch);
  }

  modal.value.accept();
};

const resetNames = () => {
  for (const itemRef of itemRefs.value) {
    itemRef.reset();
  }
};

const reset = () => {
  resetNames();
};

watch(presetsState, (newPresets, oldPresets) => {
  if (!deepEqual(newPresets, oldPresets)) {
    resetNames();
  }
});
</script>
