import getNestedObject from '@/utils/getNestedObject';
import { deepEqual, deepMerge, isArray } from '@slideslive/fuse-kit/utils';
import { defineStore } from 'pinia';
import { computed, reactive, readonly, ref, unref } from 'vue';

const useDataStore = defineStore('data', () => {
  const sessionId = ref();
  const remoteStateDisabled = ref(false);

  const remoteState = ref({});
  const draftState = ref({});
  const state = reactive({});

  const isLoading = ref(true);
  const isFetching = ref(false);
  const isError = ref(false);
  const errors = ref([]);
  const message = ref('');

  const loadingRequestsQueue = reactive({ current: [], next: [] });
  const loadingRequests = computed(() => [...loadingRequestsQueue.current, ...loadingRequestsQueue.next]);
  const clearFromDraftOnPollingQueue = reactive({ current: [], next: [] });
  const clearFromDraftOnPolling = computed(() => [
    ...clearFromDraftOnPollingQueue.current,
    ...clearFromDraftOnPollingQueue.next,
  ]);

  const updateState = () => {
    Object.keys(remoteState.value).forEach((key) => {
      if (key in draftState.value) {
        state[key] = deepMerge(remoteState.value[key], draftState.value[key], { replaceArrays: true });
      } else if (!deepEqual(state[key], remoteState.value[key])) {
        state[key] = remoteState.value[key];
      }
    });
  };

  const updateDraftState = (path, value) => {
    if (!path) {
      draftState.value = deepMerge(draftState.value, value, { replaceArrays: true });
    } else {
      const { object, key } = getNestedObject(draftState.value, path);

      object[key] = value;
    }

    updateState();
  };

  const clearDraftState = (path) => {
    if (!path) {
      draftState.value = {};

      return;
    }

    const { pathArray, object, key } = getNestedObject(draftState.value, path);

    delete object[key];

    if (pathArray.length > 1 && Object.keys(object).length === 0) {
      clearDraftState(pathArray.slice(0, -1));
    }
  };

  const scheduleDraftClear = (value) => {
    if (isFetching.value) {
      clearFromDraftOnPollingQueue.next.push(value);
    } else {
      clearFromDraftOnPollingQueue.current.push(value);
    }
  };

  const updateRemoteState = (newData) => {
    newData = unref(newData);

    if (!newData) {
      remoteState.value = {};

      return;
    }

    const { data, success, errors: newErrors, session_id: newSessionId, message: newMessage } = newData;

    if (sessionId.value !== newSessionId) {
      sessionId.value = newSessionId;
    }

    if (message.value !== newMessage) {
      message.value = newMessage;
    }

    if (newErrors?.length) {
      isError.value = true;
      errors.value = newErrors;
    } else {
      isError.value = false;
      errors.value = [];
    }

    if (!success) {
      remoteStateDisabled.value = true;

      return;
    }

    Object.keys(data).forEach((key) => {
      if (!deepEqual(remoteState.value[key], data[key])) {
        remoteState.value[key] = data[key];
      }
    });

    if (clearFromDraftOnPollingQueue.current.length || clearFromDraftOnPollingQueue.next.length) {
      clearFromDraftOnPollingQueue.current.forEach((path) => {
        clearDraftState(path);
      });

      clearFromDraftOnPollingQueue.current = clearFromDraftOnPollingQueue.next;
      clearFromDraftOnPollingQueue.next = [];
    }

    updateState();

    if (loadingRequestsQueue.current.length || loadingRequestsQueue.next.length) {
      loadingRequestsQueue.current = loadingRequestsQueue.next;
      loadingRequestsQueue.next = [];
    }
  };

  const setLoadingRequest = (value) => {
    if (isFetching.value) {
      loadingRequestsQueue.next.push(value);
    } else {
      loadingRequestsQueue.current.push(value);
    }
  };

  const setSessionId = (value) => (sessionId.value = unref(value));
  const setIsLoading = (value) => (isLoading.value = unref(value));
  const setIsFetching = (value) => (isFetching.value = unref(value));
  const setIsError = (value) => (isError.value = unref(value));
  const setError = (value) => (errors.value = isArray(unref(value)) ? unref(value) : [unref(value)]);

  return {
    sessionId: readonly(sessionId),
    remoteStateDisabled: readonly(remoteStateDisabled),
    state: readonly(state),
    loadingRequests: readonly(loadingRequests),
    clearFromDraftOnPolling: readonly(clearFromDraftOnPolling),

    isLoading: readonly(isLoading),
    isFetching: readonly(isFetching),
    isError: readonly(isError),
    errors: readonly(errors),

    setSessionId,
    setLoadingRequest,

    setIsLoading,
    setIsFetching,
    setIsError,
    setError,

    updateRemoteState,
    updateDraftState,
    scheduleDraftClear,
  };
});

export default useDataStore;
