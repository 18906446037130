const roundToStep = (value, step = null) => {
  step = Number(step ?? 1);

  const stepDecimalPlaces = (step.toString().split(/[.,]/)[1] || '').length;
  const multiplier = 10 ** stepDecimalPlaces;
  const roundedToStep = Math.round(value / step) * step;
  const roundedToDecimalPlaces = Math.round(roundedToStep * multiplier) / multiplier;

  return roundedToDecimalPlaces;
};

export default roundToStep;
