<template>
  <FormField
    v-model="name"
    :disabled="locked"
    maxlength="8"
    :label="$t('presets_modal.field.label', { number: index + 1 })"
  />
</template>

<script setup>
import useAppStore from '@/stores/app';
import { FormField } from '@slideslive/fuse-kit/vue';
import { ref, toRefs } from 'vue';

const props = defineProps({
  preset: {
    type: Object,
    required: true,
  },
  index: {
    type: Number,
    required: true,
  },
});

const appStore = useAppStore();
const { locked } = toRefs(appStore);

const name = ref(props.preset.name);

const getData = () => ({
  ...props.preset,
  name: name.value,
});

const reset = () => {
  name.value = props.preset.name;
};

defineExpose({ getData, reset });
</script>
