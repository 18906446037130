import useDataStore from '@/stores/data';
import { generateRandomId } from '@slideslive/fuse-kit/utils';
import { storeToRefs } from 'pinia';
import { computed, readonly, ref, unref } from 'vue';

function useLoadingUntilRequested(queryFn, { disabled = false } = {}) {
  const dataStore = useDataStore();
  const { setLoadingRequest } = dataStore;
  const { loadingRequests } = storeToRefs(dataStore);

  const queryId = generateRandomId(20);
  const triggered = ref(false);
  const loading = computed(() => triggered.value || loadingRequests.value.includes(queryId));

  const trigger = async () => {
    if (unref(disabled) || unref(loading)) return;

    triggered.value = true;

    await queryFn();
    setLoadingRequest(queryId);

    triggered.value = false;
  };

  return {
    loading: readonly(loading),
    trigger,
  };
}

export default useLoadingUntilRequested;
