<template>
  <Teleport to="body">
    <Modal v-model="show">
      <ModalContent size="large">
        <template #header>
          <Heading level="h2" level-class="h3">{{ $t('changelog_modal.title') }}</Heading>
        </template>

        <template #footer="{ reject }">
          <Button color="transparent" outlined @click="reject">{{ $t('cancel') }}</Button>
        </template>

        <div class="tw-fuse-prose">{{ API_BASE_URL }}</div>
      </ModalContent>
    </Modal>
  </Teleport>
</template>

<script setup>
import { API_BASE_URL } from '@/api';
import { Button, Heading, Modal, ModalContent } from '@slideslive/fuse-kit/vue';

const show = defineModel();
</script>
