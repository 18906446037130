<template>
  <Tabs class="tw-w-full tw-max-w-[20.125rem] tw-shrink-0 tw-self-stretch" change-on-focus>
    <TabList>
      <Tab :disabled="locked">{{ $t('speakers_presets_tabs.speakers.label') }}</Tab>
      <Tab :disabled="locked">{{ $t('speakers_presets_tabs.presets.label') }}</Tab>
    </TabList>

    <TabPanels class="tw-mt-3 tw-flex-1">
      <Speakers />
      <Presets />
    </TabPanels>
  </Tabs>
</template>

<script setup>
import useAppStore from '@/stores/app';
import { Tab, TabList, TabPanels, Tabs } from '@slideslive/fuse-kit/vue';
import { storeToRefs } from 'pinia';

import Presets from './Presets.vue';
import Speakers from './Speakers.vue';

const appStore = useAppStore();
const { locked } = storeToRefs(appStore);
</script>
