import fetcher from './fetcher';

export const API_BASE_URL = 'https://example.com/';

const abortControllers = new WeakMap();

const withAbortController =
  (queryFn) =>
  (data = null, { abortController = null, ...rest } = {}) => {
    if (abortControllers.has(queryFn)) {
      abortControllers.get(queryFn).abort();
    }

    abortController = abortController || new AbortController();

    abortControllers.set(queryFn, abortController);

    return queryFn(data, { abortController, ...rest }).then((response) => {
      abortControllers.delete(queryFn);

      return response;
    });
  };

const api = {
  open: withAbortController(fetcher.get.bind(fetcher, '/open')),
  state: withAbortController(fetcher.get.bind(fetcher, '/state')),
  changelog: withAbortController(fetcher.get.bind(fetcher, '/files/changelog')),
  patchState: withAbortController(fetcher.patch.bind(fetcher, '/state')),
  patchSwitcher: withAbortController(fetcher.patch.bind(fetcher, '/switcher')),
  patchRecorders: withAbortController(fetcher.patch.bind(fetcher, '/recorders')),
  resetNateSettings: withAbortController(fetcher.post.bind(fetcher, '/settings/-/reset_nate_settings')),
  resetMainCamSettings: withAbortController(fetcher.post.bind(fetcher, '/settings/-/reset_main_camera_settings')),
  resetCamPlatform: withAbortController(fetcher.patch.bind(fetcher, '/settings/-/camera_platform_reset')),
  resetToHomeView: withAbortController(fetcher.post.bind(fetcher, '/settings/-/reset_to_home_view')),
  setAsHomeView: withAbortController(fetcher.post.bind(fetcher, '/settings/-/set_as_home_view')),
  startAllRecording: withAbortController(fetcher.post.bind(fetcher, '/recorders/-/start_all')),
  stopAllRecording: withAbortController(fetcher.post.bind(fetcher, '/recorders/-/stop_all')),
  startRecording: withAbortController(fetcher.post.bind(fetcher, '/recorders/:recorder_id/-/start')),
  stopRecording: withAbortController(fetcher.post.bind(fetcher, '/recorders/:recorder_id/-/stop')),
  deleteSpeaker: withAbortController(fetcher.delete.bind(fetcher, '/speakers/:speaker_id', null)),
  deleteInactiveSpeakers: withAbortController(fetcher.post.bind(fetcher, '/speakers/-/remove_inactive', null)),
  deleteAllSpeakers: withAbortController(fetcher.post.bind(fetcher, '/speakers/-/remove_all', null)),
  savePreset: withAbortController(fetcher.post.bind(fetcher, '/presets/:preset_id/-/save_current_view')),
  setPreset: withAbortController(fetcher.get.bind(fetcher, '/move/to_preset?preset_id=:preset_id')),
  setWatcherMode: withAbortController(fetcher.post.bind(fetcher, '/settings/-/set_watcher_mode')),
  setTrackerMode: withAbortController(fetcher.post.bind(fetcher, '/settings/-/set_tracker_mode')),
};

export default api;
