<template>
  <ControlSection>
    <FormTag
      v-model="model"
      :disabled="locked"
      type="radio"
      name="controlAction"
      value="move_pan_tilt"
      :label="$t('control_modes.move.pan_tilt')"
      class="tw-whitespace-nowrap"
    />

    <div class="tw-flex tw-items-center tw-gap-x-2">
      <CircularButton
        v-for="move in cameraMoves"
        :key="move.direction"
        :icon="move.icon || `arrow-${move.direction}`"
        color="semiTransparentWhite"
        shape="rounded"
        size="large"
        :disabled="locked"
        :aria-label="move.label"
        @click="moveCamera(move.direction)"
      />
    </div>
  </ControlSection>
</template>

<script setup>
import useAppStore from '@/stores/app';
import { CircularButton, FormTag } from '@slideslive/fuse-kit/vue';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';

import ControlSection from './ControlSection.vue';

const { t } = useI18n();
const appStore = useAppStore();
const { locked } = storeToRefs(appStore);
const model = defineModel();

const cameraMoves = [
  {
    direction: 'up',
    label: t('control_modes.move.up'),
  },
  {
    direction: 'down',
    label: t('control_modes.move.down'),
  },
  {
    direction: 'left',
    label: t('control_modes.move.left'),
  },
  {
    direction: 'right',
    label: t('control_modes.move.right'),
  },
  {
    direction: 'zoom_in',
    label: t('control_modes.move.zoom_in'),
    icon: 'zoom-in',
  },
  {
    direction: 'zoom_out',
    label: t('control_modes.move.zoom_out'),
    icon: 'zoom-out',
  },
];

const moveCamera = (direction) => {
  console.log('move camera', direction);
};
</script>
