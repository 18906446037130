<template>
  <nav v-bind="$attrs" class="tw-text-lg">
    <ul class="tw-flex tw-gap-2">
      <li
        v-for="item in navItems"
        :key="item.id"
        class="tw-flex after:tw-ml-2 after:tw-w-0.5 after:tw-self-stretch after:tw-bg-purple/25 last:after:tw-content-none"
      >
        <Link v-if="item.href" :href="item.href" target="_blank" scheme="underline">{{ item.label }}</Link>

        <Dropdown
          v-else-if="item.items"
          :overlay="false"
          :trigger-attrs="{ linkButton: true, reset: true, scheme: 'underline' }"
        >
          <template #trigger>{{ item.label }}</template>

          <template #menu>
            <Button
              v-for="item in item.items"
              :key="item.id"
              tag="a"
              :href="item.href"
              target="_blank"
              color="transparentWhite"
              size="small"
              class="tw-text-left"
              block
            >
              {{ item.label }}
            </Button>
          </template>
        </Dropdown>

        <Link v-else-if="item.onClick" tag="button" scheme="underline" @click="item.onClick">{{ item.label }}</Link>
      </li>
    </ul>
  </nav>

  <ChangelogModal v-model="changelogOpen" />
</template>

<script setup>
import useDataStore from '@/stores/data';
import { Button, Dropdown, Link } from '@slideslive/fuse-kit/vue';
import { computed, ref, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';

import ChangelogModal from './ChangelogModal.vue';

const { t } = useI18n();
const dataStore = useDataStore();
const { app } = toRefs(dataStore.state);
const changelogOpen = ref(false);

const openChangelog = () => {
  changelogOpen.value = true;
};

const navItems = computed(() => {
  const items = [];

  if (app?.value?.main_cam_url) {
    items.push({
      id: 'main-cam',
      label: t('header_navigation.main_cam'),
      href: app.value.main_cam_url,
    });
  }

  if (app?.value?.wide_cam_url) {
    items.push({
      id: 'wide-cam',
      label: t('header_navigation.wide_cam'),
      href: app.value.wide_cam_url,
    });
  }

  items.push({
    id: 'control-room',
    label: t('header_navigation.control_room'),
    items: [
      {
        id: 'control-room-eu-server',
        label: t('header_navigation.control_room_eu_server'),
        href: 'http://control-room.eu.nate.slideslive.com/',
      },
      {
        id: 'control-room-us-server',
        label: t('header_navigation.control_room_us_server'),
        href: 'http://control-room.us.nate.slideslive.com/',
      },
    ],
  });

  if (app?.value?.nate_runner_link) {
    items.push({
      id: 'nate-runner',
      label: t('header_navigation.nate_runner'),
      href: app.value.nate_runner_link,
    });
  }

  items.push({
    id: 'about',
    label: t('header_navigation.about'),
    href: 'https://slideslive.notion.site/About-RoboCam-and-NATE-2b249515bc3e480a9c549d05d4a09f26',
  });

  items.push({
    id: 'changelog',
    label: app?.value?.nate_version?.value || t('header_navigation.unknown_version'),
    onClick: openChangelog,
  });

  return items;
});
</script>
