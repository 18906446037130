<template>
  <div class="tw-space-y-5">
    <Heading level="h3" level-class="h4">{{ $t('settings_modal.personal.title') }}</Heading>

    <div class="tw-flex tw-flex-wrap tw-items-center tw-gap-5">
      <Checkbox
        v-model="tooltips"
        type="switch"
        :disabled="locked"
        :right-label="$t('settings_modal.personal.tooltips.label')"
      />

      <Checkbox
        v-model="previewInfo"
        type="switch"
        :disabled="locked"
        :right-label="$t('settings_modal.personal.preview_info.label')"
      />

      <Checkbox
        v-model="resourcesUsage"
        type="switch"
        :disabled="locked"
        :right-label="$t('settings_modal.personal.resources_usage.label')"
      />

      <FormField
        v-model.number="numberOfVisibleSpeakers"
        type="number"
        :disabled="locked"
        :min="1"
        :label="$t('settings_modal.personal.number_of_visible_speakers.label', { to: numberOfVisibleSpeakers })"
        class="tw-w-full sm:tw-w-[calc(100%/2-0.625rem)] md:tw-w-[calc(100%/3-0.625rem)] lg:tw-w-[calc(100%/4-0.625rem)]"
      />
    </div>
  </div>
</template>

<script setup>
import useAppStore from '@/stores/app';
import { Checkbox, FormField, Heading } from '@slideslive/fuse-kit/vue';
import { toRefs } from 'vue';

const appStore = useAppStore();
const { locked } = toRefs(appStore);

const tooltips = defineModel('tooltips');
const previewInfo = defineModel('previewInfo');
const resourcesUsage = defineModel('resourcesUsage');
const numberOfVisibleSpeakers = defineModel('numberOfVisibleSpeakers');
</script>
