<template>
  <div class="tw-space-y-5">
    <Heading level="h3" level-class="h4">{{ $t('settings_modal.preview.title') }}</Heading>

    <div class="tw-grid tw-grid-cols-1 tw-gap-5 sm:tw-grid-cols-2 md:tw-grid-cols-3 lg:tw-grid-cols-4">
      <FormField
        v-model="previewQuality"
        type="select"
        :disabled="locked"
        :label="$t('settings_modal.preview.preview_quality.label')"
      >
        <option v-for="option in previewQualityOptions" :key="option.value" :value="option.value">
          {{ option.label }}
        </option>
      </FormField>

      <FormField
        v-model.number="fps"
        type="number"
        :disabled="locked"
        :min="fpsState.min"
        :max="fpsState.max"
        :step="fpsState.step"
        :label="$t('settings_modal.preview.fps.label', { from: fpsState.min, to: fpsState.max })"
      />

      <FormField
        v-model.number="encoderQuality"
        type="number"
        :disabled="locked"
        :min="encoderQualityState.min"
        :max="encoderQualityState.max"
        :step="encoderQualityState.step"
        :label="
          $t('settings_modal.preview.encoder_quality.label', {
            from: encoderQualityState.min,
            to: encoderQualityState.max,
          })
        "
      />

      <FormField
        v-model="encoderType"
        type="select"
        :disabled="locked"
        :label="$t('settings_modal.preview.encoder_type.label')"
      >
        <option v-for="option in encoderTypeState.options" :key="option" :value="option">
          {{ option }}
        </option>
      </FormField>

      <FormField :value="bitrate" :readonly :label="$t('settings_modal.preview.approximate_bitrate.label')" />
    </div>
  </div>
</template>

<script setup>
import useAppStore from '@/stores/app';
import useDataStore from '@/stores/data';
import { FormField, Heading } from '@slideslive/fuse-kit/vue';
import { computed, onMounted, readonly, ref, toRefs, watch } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const previewQualityOptions = {
  low: {
    label: t('settings_modal.preview.preview_quality.options.low'),
    value: 'low',
    fps: 5,
    encoderQuality: 10,
    encoderType: 'webp',
  },
  medium: {
    label: t('settings_modal.preview.preview_quality.options.medium'),
    value: 'medium',
    fps: 12,
    encoderQuality: 30,
    encoderType: 'webp',
  },
  high: {
    label: t('settings_modal.preview.preview_quality.options.high'),
    value: 'high',
    fps: 12,
    encoderQuality: 60,
    encoderType: 'webp',
  },
};

const appStore = useAppStore();
const { locked } = toRefs(appStore);
const dataStore = useDataStore();
const { controller } = toRefs(dataStore.state);
const {
  output_gui_video_fps: fpsState,
  output_gui_video_quality: encoderQualityState,
  output_gui_video_encoder: encoderTypeState,
} = toRefs(controller.value);

const previewQuality = ref('');
const fps = defineModel('fps');
const encoderQuality = defineModel('encoderQuality');
const encoderType = defineModel('encoderType');

const bitrate = computed(() => {
  switch (encoderTypeState.value.value) {
    case 'webp':
      return Math.round((5 * encoderQualityState.value.value + 50) * fpsState.value.value);
    case 'jpg':
      return Math.round((6 * encoderQualityState.value.value + 140) * fpsState.value.value);
    default:
      return 'unknown';
  }
});

const resetPreviewQuality = () => {
  switch (encoderQualityState.value.value) {
    case 10:
      previewQuality.value = 'low';
      break;
    case 30:
      previewQuality.value = 'medium';
      break;
    case 60:
      previewQuality.value = 'high';
      break;
    default:
      previewQuality.value = 'medium';
  }
};

watch(previewQuality, (newQuality) => {
  const {
    fps: defaultFps,
    encoderQuality: defaultEncoderQuality,
    encoderType: defaultEncoderType,
  } = previewQualityOptions[newQuality];

  fps.value = defaultFps;
  encoderQuality.value = defaultEncoderQuality;
  encoderType.value = defaultEncoderType;
});

onMounted(() => {
  resetPreviewQuality();
});

const api = { resetPreviewQuality };

defineExpose(api);
</script>
