<template>
  <header
    v-bind="$attrs"
    class="tw-flex tw-min-h-20 tw-items-center tw-justify-between tw-border-b-2 tw-border-purple/25 tw-py-2"
  >
    <div class="tw-flex tw-items-center tw-space-x-10">
      <div>
        <Heading level="h1" level-class="h2">{{ title }}</Heading>
        <Text v-if="state?.app?.track_name" class="tw-mt-1">{{ state.app.track_name }}</Text>
      </div>

      <HeaderNavigation />
    </div>

    <div class="tw-flex tw-items-center tw-space-x-2">
      <Icon v-if="connectionIcon" :name="connectionIcon" class="tw-mr-5 tw-size-6 tw-fill-current" />

      <CircularButton
        icon="settings"
        color="semiTransparentWhite"
        shape="rounded"
        size="large"
        :aria-label="$t('settings')"
        @click="openSettings"
      />

      <CircularButton
        :icon="lockIcon"
        :color="lockColor"
        shape="rounded"
        size="large"
        :aria-label="$t('lock')"
        @click="toggleLock"
      />
    </div>
  </header>

  <SettingsModal v-model="settingsOpen" />
</template>

<script setup>
import useAppStore from '@/stores/app';
import useDataStore from '@/stores/data';
import { CircularButton, Heading, Icon, Text } from '@slideslive/fuse-kit/vue';
import { storeToRefs } from 'pinia';
import { computed, ref, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';

import HeaderNavigation from './HeaderNavigation.vue';
import SettingsModal from './settingsModal/SettingsModal.vue';

const { t } = useI18n();
const appStore = useAppStore();
const { setLocked } = appStore;
const { locked } = storeToRefs(appStore);
const dataStore = useDataStore();
const { state } = toRefs(dataStore);
const { app, router } = toRefs(dataStore.state);
const title = computed(() => app.value.station_name.slice(0, 25) || t('default_station_name'));
const settingsOpen = ref(false);

const connectionIcon = computed(() => {
  switch (router.value.wan_interface) {
    case 'wifi':
      return router.value.internet_connected ? 'wifi' : 'wifi-off';
    case 'ethernet':
      return router.value.internet_connected ? 'ethernet' : 'ethernet-off';
    default:
      return null;
  }
});

const lockIcon = computed(() => (locked.value ? 'lock' : 'lock-open'));
const lockColor = computed(() => (locked.value ? 'red' : 'semiTransparentWhite'));

const openSettings = () => {
  settingsOpen.value = true;
};

const toggleLock = () => {
  setLocked(!locked.value);
};
</script>
