<template>
  <ControlSection>
    <div class="tw-flex tw-flex-col tw-space-y-2">
      <div class="tw-flex tw-items-center tw-gap-x-5">
        <FormTag
          v-model="model"
          :disabled="locked"
          type="radio"
          name="controlAction"
          value="add_speaker"
          :label="$t('control_modes.speakers.add_speaker')"
          class="tw-whitespace-nowrap"
        />

        <Checkbox
          v-model="autoSpeakerSelection"
          :disabled="locked"
          type="switch"
          :right-label="$t('control_modes.speakers.auto_selection')"
        />

        <Checkbox
          v-if="controlMode === 'tracker'"
          v-model="autoSpeakerRemoval"
          :disabled="locked"
          type="switch"
          :right-label="$t('control_modes.speakers.strict_mode')"
        />
      </div>

      <div class="tw-flex tw-items-center tw-gap-x-5">
        <FormTag
          v-model="model"
          :disabled="locked"
          type="radio"
          name="controlAction"
          value="ignore_speaker"
          :label="$t('control_modes.speakers.ignore_speaker')"
          class="tw-whitespace-nowrap"
        />

        <Checkbox
          v-model="strictMode"
          :disabled="locked"
          type="switch"
          :right-label="$t('control_modes.speakers.auto_removal')"
        />

        <Checkbox
          v-if="controlMode === 'tracker'"
          v-model="singleSpeakerMode"
          :disabled="locked"
          type="switch"
          :right-label="$t('control_modes.speakers.single_speaker_mode')"
        />
      </div>
    </div>

    <div class="tw-flex tw-items-center tw-gap-x-2"></div>
  </ControlSection>
</template>

<script setup>
import useAppStore from '@/stores/app';
import useDataStore from '@/stores/data';
import { Checkbox, FormTag } from '@slideslive/fuse-kit/vue';
import { storeToRefs } from 'pinia';
import { ref, toRefs } from 'vue';

import ControlSection from './ControlSection.vue';

defineProps({
  controlMode: {
    type: String,
    required: true,
  },
});

const model = defineModel();
const appStore = useAppStore();
const { locked } = storeToRefs(appStore);
const dataStore = useDataStore();
const { controller } = toRefs(dataStore.state);
const {
  auto_speaker_selection: autoSpeakerSelectionState,
  auto_speaker_removal: autoSpeakerRemovalstate,
  strict_mode: strictModeState,
  single_speaker_mode: singleSpeakerModeState,
} = toRefs(controller.value);

const autoSpeakerSelection = ref(autoSpeakerSelectionState.value);
const autoSpeakerRemoval = ref(autoSpeakerRemovalstate.value);
const strictMode = ref(strictModeState.value);
const singleSpeakerMode = ref(singleSpeakerModeState.value);
</script>
