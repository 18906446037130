<template>
  <Teleport to="body">
    <Modal ref="modal" v-model="show" :persistent="saving" @opening="reset" @close="reset">
      <ModalContent size="xlarge">
        <template #header>
          <Heading level="h2" level-class="h3">{{ $t('settings_modal.title') }}</Heading>
        </template>

        <template #footer="{ reject }">
          <Button :loading="saving" color="transparent" outlined @click="reject">{{ $t('cancel') }}</Button>
          <Button :loading="saving" :disabled="locked" type="submit" form="settings-form">{{ $t('save') }}</Button>
        </template>

        <Form id="settings-form" class="tw-space-y-5" @submit="triggerSave">
          <Setup
            v-model:trackName="trackName"
            v-model:vMixIpPort="vMixIpPort"
            v-model:vMixMainCamLabel="vMixMainCamLabel"
            v-model:vMixWideCamLabel="vMixWideCamLabel"
            v-model:vMixNateCamLabel="vMixNateCamLabel"
          />

          <Preview
            ref="preview"
            v-model:fps="fps"
            v-model:encoderQuality="encoderQuality"
            v-model:encoderType="encoderType"
          />

          <Personal
            v-model:tooltips="tooltips"
            v-model:previewInfo="previewInfo"
            v-model:resourcesUsage="resourcesUsage"
            v-model:numberOfVisibleSpeakers="numberOfVisibleSpeakers"
          />

          <Other />
        </Form>
      </ModalContent>
    </Modal>
  </Teleport>
</template>

<script setup>
import api from '@/api';
import useDraftState from '@/composables/draftState';
import useLoadingUntilRequested from '@/composables/loadingUntilRequested';
import useAppStore from '@/stores/app';
import useDataStore from '@/stores/data';
import setIfChanged from '@/utils/setIfChanged';
import { capitalizeFirstLetter } from '@slideslive/fuse-kit/utils';
import { Button, Form, Heading, Modal, ModalContent } from '@slideslive/fuse-kit/vue';
import { ref, toRefs, unref, watch } from 'vue';

import Other from './Other.vue';
import Personal from './Personal.vue';
import Preview from './Preview.vue';
import Setup from './Setup.vue';

const appStore = useAppStore();
const {
  locked,
  tooltips: tooltipsStore,
  previewInfo: previewInfoStore,
  resourcesUsage: resourcesUsageStore,
  numberOfVisibleSpeakers: numberOfVisibleSpeakersStore,
} = toRefs(appStore);

const dataStore = useDataStore();
const { app, controller, switcher } = toRefs(dataStore.state);
const { track_name: trackNameState } = toRefs(app.value);
const {
  vmix_ip_port: vMixIpPortState,
  vmix_main_cam_label: vMixMainCamLabelState,
  vmix_wide_cam_label: vMixWideCamLabelState,
  vmix_nate_cam_label: vMixNateCamLabelState,
} = toRefs(switcher.value);
const {
  output_gui_video_fps: fpsState,
  output_gui_video_quality: encoderQualityState,
  output_gui_video_encoder: encoderTypeState,
} = toRefs(controller.value);

const modal = ref(null);
const preview = ref(null);
const show = defineModel();
const trackName = ref(trackNameState.value);
const vMixIpPort = ref(vMixIpPortState.value);
const vMixMainCamLabel = ref(vMixMainCamLabelState.value);
const vMixWideCamLabel = ref(vMixWideCamLabelState.value);
const vMixNateCamLabel = ref(vMixNateCamLabelState.value);
const fps = ref(fpsState.value.value);
const encoderQuality = ref(encoderQualityState.value.value);
const encoderType = ref(encoderTypeState.value.value);
const tooltips = ref(tooltipsStore.value);
const previewInfo = ref(previewInfoStore.value);
const resourcesUsage = ref(resourcesUsageStore.value);
const numberOfVisibleSpeakers = ref(numberOfVisibleSpeakersStore.value);

const { trigger: setStateApp } = useDraftState(api.patchState, { statePathToClear: ['app'] });
const { trigger: setStateController } = useDraftState(api.patchState, { statePathToClear: ['controller'] });
const { trigger: setSwitcher } = useDraftState(api.patchSwitcher, { statePath: ['switcher'] });

const save = async () => {
  const stateAppPatch = { app: {} };
  const stateControllerPatch = { controller: {} };
  const switcherPatch = {};
  const settingsPatch = {};

  setIfChanged(stateAppPatch.app, 'track_name', trackNameState, trackName);
  setIfChanged(stateControllerPatch.controller, 'output_gui_video_fps', fpsState.value.value, { value: fps.value });
  setIfChanged(stateControllerPatch.controller, 'output_gui_video_quality', encoderQualityState.value.value, {
    value: encoderQuality.value,
  });
  setIfChanged(stateControllerPatch.controller, 'output_gui_video_encoder', encoderTypeState.value.value, {
    value: encoderType.value,
  });

  setIfChanged(switcherPatch, 'vmix_ip_port', vMixIpPortState, vMixIpPort);
  setIfChanged(switcherPatch, 'vmix_main_cam_label', vMixMainCamLabelState, vMixMainCamLabel);
  setIfChanged(switcherPatch, 'vmix_wide_cam_label', vMixWideCamLabelState, vMixWideCamLabel);
  setIfChanged(switcherPatch, 'vmix_nate_cam_label', vMixNateCamLabelState, vMixNateCamLabel);

  setIfChanged(settingsPatch, 'tooltips', tooltipsStore, tooltips);
  setIfChanged(settingsPatch, 'previewInfo', previewInfoStore, previewInfo);
  setIfChanged(settingsPatch, 'resourcesUsage', resourcesUsageStore, resourcesUsage);
  setIfChanged(settingsPatch, 'numberOfVisibleSpeakers', numberOfVisibleSpeakersStore, numberOfVisibleSpeakers);

  for (const key of Object.keys(settingsPatch)) {
    appStore[`set${capitalizeFirstLetter(key)}`](unref(settingsPatch[key]));
  }

  const promises = [];

  if (Object.keys(stateAppPatch).length) {
    promises.push(setStateApp(stateAppPatch));
  }

  if (Object.keys(stateControllerPatch).length) {
    promises.push(setStateController(stateControllerPatch));
  }

  if (Object.keys(switcherPatch).length) {
    promises.push(setSwitcher(switcherPatch));
  }

  return Promise.all(promises);
};

const reset = () => {
  trackName.value = trackNameState.value;
  vMixIpPort.value = vMixIpPortState.value;
  vMixMainCamLabel.value = vMixMainCamLabelState.value;
  vMixWideCamLabel.value = vMixWideCamLabelState.value;
  vMixNateCamLabel.value = vMixNateCamLabelState.value;
  fps.value = fpsState.value.value;
  encoderQuality.value = encoderQualityState.value.value;
  encoderType.value = encoderTypeState.value.value;
  tooltips.value = tooltipsStore.value;
  previewInfo.value = previewInfoStore.value;
  resourcesUsage.value = resourcesUsageStore.value;
  numberOfVisibleSpeakers.value = numberOfVisibleSpeakersStore.value;

  preview.value.resetPreviewQuality();
};

const { loading: saving, trigger: triggerSave } = useLoadingUntilRequested(save, { disabled: locked });

watch(saving, (newSaving, oldSaving) => {
  if (oldSaving && !newSaving) {
    modal.value.accept();
  }
});
</script>
