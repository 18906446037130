<template>
  <div class="tw-space-y-5">
    <Heading level="h3" level-class="h4">{{ $t('settings_modal.setup.title') }}</Heading>

    <ol class="tw-list-decimal tw-pl-6">
      <li class="tw-mb-5 tw-space-y-4">
        <Text>
          <i18n-t keypath="settings_modal.setup.load_default_message">
            <template #default_settings>
              <strong class="tw-font-black">{{ $t('settings_modal.setup.strong.default_settings') }}</strong>
            </template>

            <template #main_camera_link>
              <Link :href="app.main_cam_url" scheme="underline" target="_blank">{{
                $t('settings_modal.setup.main_camera')
              }}</Link>
            </template>
          </i18n-t>
        </Text>

        <div class="tw--ml-6 tw-flex tw-flex-wrap tw-items-start tw-gap-2">
          <Button color="semiTransparentWhite" :disabled="locked">
            {{ $t('settings_modal.setup.load_default_nate') }}
          </Button>

          <Button color="semiTransparentWhite" :disabled="locked">
            {{ $t('settings_modal.setup.load_default_camera') }}
          </Button>
        </div>
      </li>

      <li class="tw-mb-5 tw-space-y-4">
        <Text>
          <i18n-t keypath="settings_modal.setup.track_name_message">
            <template #track_name>
              <strong class="tw-font-black">{{ $t('settings_modal.setup.strong.track_name') }}</strong>
            </template>
          </i18n-t>
        </Text>

        <div class="tw--ml-6 tw-grid tw-grid-cols-1 tw-gap-5 sm:tw-grid-cols-2 md:tw-grid-cols-3 lg:tw-grid-cols-4">
          <FormField
            v-model="trackName"
            :disabled="locked"
            :maxlength="25"
            :label="$t('settings_modal.setup.track_name.label')"
          />
        </div>
      </li>

      <li class="tw-mb-5 tw-space-y-4 last:tw-mb-0">
        <Text>
          <i18n-t keypath="settings_modal.setup.vmix_message">
            <template #vmix_ip>
              <strong class="tw-font-black">{{ $t('settings_modal.setup.strong.vmix_ip') }}</strong>
            </template>

            <template #labels>
              <strong class="tw-font-black">{{ $t('settings_modal.setup.strong.labels') }}</strong>
            </template>
          </i18n-t>
        </Text>

        <div class="tw--ml-6 tw-grid tw-grid-cols-1 tw-gap-5 sm:tw-grid-cols-2 md:tw-grid-cols-3 lg:tw-grid-cols-4">
          <FormField v-model="vMixIpPort" :disabled="locked" :label="$t('settings_modal.setup.vmix_ip_port.label')" />

          <FormField
            v-model="vMixMainCamLabel"
            :disabled="locked"
            :label="$t('settings_modal.setup.cam1_label.label')"
          />

          <FormField
            v-model="vMixWideCamLabel"
            :disabled="locked"
            :label="$t('settings_modal.setup.wideshot_cam_label.label')"
          />

          <FormField
            v-model="vMixNateCamLabel"
            :disabled="locked"
            :label="$t('settings_modal.setup.nate_cam_label.label')"
          />
        </div>
      </li>

      <template v-if="showAllSetupSteps">
        <li class="tw-space-y-4">
          <Text>
            <span v-html="$t('settings_modal.setup.camera_position_message')"></span>
          </Text>
        </li>

        <li class="tw-space-y-4">
          <Text>
            <i18n-t keypath="settings_modal.setup.exposure_white_balance_message">
              <template #main_camera_link>
                <Link :href="app.main_cam_url" scheme="underline" target="_blank">{{
                  $t('settings_modal.setup.main_camera')
                }}</Link>
              </template>

              <template #wideshot_camera_link>
                <Link :href="app.wide_cam_url" scheme="underline" target="_blank">{{
                  $t('settings_modal.setup.wideshot_camera')
                }}</Link>
              </template>
            </i18n-t>
          </Text>
        </li>

        <li class="tw-space-y-4">
          <Text>
            <i18n-t keypath="settings_modal.setup.main_audio_message">
              <template #main_camera_link>
                <Link :href="app.main_cam_url" scheme="underline" target="_blank">{{
                  $t('settings_modal.setup.main_camera')
                }}</Link>
              </template>
            </i18n-t>
          </Text>
        </li>

        <li class="tw-space-y-4">
          <Text>
            <i18n-t keypath="settings_modal.setup.wideshot_audio_message">
              <template #wideshot_camera_link>
                <Link :href="app.wide_cam_url" scheme="underline" target="_blank">{{
                  $t('settings_modal.setup.wideshot_camera')
                }}</Link>
              </template>
            </i18n-t>
          </Text>
        </li>

        <li class="tw-space-y-4">
          <Text>
            <span v-html="$t('settings_modal.setup.tracking_zone_message')"></span>
          </Text>
        </li>

        <li class="tw-space-y-4">
          <Text>
            <span v-html="$t('settings_modal.setup.tracker_mode_message')"></span>
          </Text>
        </li>
      </template>
    </ol>

    <Button color="transparentWhite" @click="toggleSetupSteps">
      <template v-if="!showAllSetupSteps">
        {{ $t('settings_modal.setup.show_more_steps') }}
      </template>

      <template v-else>
        {{ $t('settings_modal.setup.show_less_steps') }}
      </template>

      <Icon
        name="chevron-down"
        class="tw-relative tw-top-px tw-ml-2 tw-size-6 tw-fill-current tw-transition-transform"
        :class="{ 'tw-rotate-180': showAllSetupSteps }"
      />
    </Button>
  </div>
</template>

<script setup>
import useAppStore from '@/stores/app';
import useDataStore from '@/stores/data';
import { Button, FormField, Heading, Icon, Link, Text } from '@slideslive/fuse-kit/vue';
import { ref, toRefs } from 'vue';

const appStore = useAppStore();
const { locked } = toRefs(appStore);
const dataStore = useDataStore();
const { app } = toRefs(dataStore.state);
const showAllSetupSteps = ref(false);

const trackName = defineModel('trackName');
const vMixIpPort = defineModel('vMixIpPort');
const vMixMainCamLabel = defineModel('vMixMainCamLabel');
const vMixWideCamLabel = defineModel('vMixWideCamLabel');
const vMixNateCamLabel = defineModel('vMixNateCamLabel');

const toggleSetupSteps = () => {
  showAllSetupSteps.value = !showAllSetupSteps.value;
};
</script>
