<template>
  <ControlSection>
    <div class="tw-flex tw-items-center tw-gap-x-2">
      <CircularButton
        v-for="move in compositionMoves"
        :key="move.direction"
        :icon="move.icon || `triangle-${move.direction}`"
        color="semiTransparentWhite"
        shape="rounded"
        size="large"
        :disabled="locked"
        :aria-label="move.label"
        @click="setComposition(move.direction)"
      />

      <Button :disabled="locked" color="semiTransparentWhite" @click="realign">
        {{ $t('control_modes.composition.realign') }}
      </Button>
    </div>

    <Checkbox
      v-model="sittingMode"
      :disabled="locked"
      type="switch"
      :right-label="$t('control_modes.composition.sitting_mode')"
    />
  </ControlSection>
</template>

<script setup>
import useAppStore from '@/stores/app';
import useDataStore from '@/stores/data';
import { Button, Checkbox, CircularButton } from '@slideslive/fuse-kit/vue';
import { storeToRefs } from 'pinia';
import { ref, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';

import ControlSection from './ControlSection.vue';

const { t } = useI18n();
const appStore = useAppStore();
const { locked } = storeToRefs(appStore);
const dataStore = useDataStore();
const { composition } = toRefs(dataStore.state);
const { sitting_mode: sittingModeState } = toRefs(composition.value);

const compositionMoves = [
  {
    direction: 'up',
    label: t('control_modes.composition.up'),
  },
  {
    direction: 'down',
    label: t('control_modes.composition.down'),
  },
  {
    direction: 'center',
    label: t('control_modes.composition.center'),
    icon: 'dot',
  },
  {
    direction: 'left',
    label: t('control_modes.composition.left'),
  },
  {
    direction: 'right',
    label: t('control_modes.composition.right'),
  },
];

const sittingMode = ref(sittingModeState.value);

const setComposition = (direction) => {
  console.log('set composition', direction);
};

const realign = () => {
  console.log('realign camera');
};
</script>
