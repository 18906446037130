<template>
  <TabPanel v-bind="$attrs" class="tw-flex tw-h-full tw-flex-col tw-justify-between">
    <ol class="tw-grid tw-grid-cols-5 tw-gap-2">
      <Speaker
        v-for="speaker in visibleSpeakers"
        :key="speaker.speaker_id"
        tag="li"
        :speaker
        :delete-disabled="deleteDisabled(speaker.speaker_id)"
        @delete="triggerDelete"
      />
    </ol>

    <Text size="large" class="tw-mt-1.5 tw-text-right">
      <Link tag="button" scheme="underline" @click="openSettings">
        {{ $t('settings') }}
      </Link>
    </Text>
  </TabPanel>

  <SpeakersModal v-model="settingsOpen" />
</template>

<script setup>
import api from '@/api';
import useDraftState from '@/composables/draftState';
import useAppStore from '@/stores/app';
import useDataStore from '@/stores/data';
import { Link, TabPanel, Text } from '@slideslive/fuse-kit/vue';
import { computed, ref, toRefs, watch } from 'vue';

import Speaker from './Speaker.vue';
import SpeakersModal from './SpeakersModal.vue';

const appStore = useAppStore();
const {
  locked,
  activeSpeakersTimeWindow: activeSpeakersTimeWindowStore,
  numberOfVisibleSpeakers: numberOfVisibleSpeakersStore,
} = toRefs(appStore);
const dataStore = useDataStore();
const { speakers: speakersState } = toRefs(dataStore.state);
const deletedIds = ref([]);

const speakers = computed(() =>
  speakersState.value.filter(({ speaker_id: speakerId }) => !deletedIds.value.includes(speakerId)),
);
const activeSpeakers = computed(() =>
  speakers.value.filter((speaker) => speaker.detection_age_seconds <= activeSpeakersTimeWindowStore.value),
);
const sortedSpeakers = computed(() => activeSpeakers.value.slice().sort((a, b) => a.x_pos - b.x_pos));
const visibleSpeakers = computed(() => sortedSpeakers.value.slice(0, numberOfVisibleSpeakersStore.value));
const settingsOpen = ref(false);

const { loading: deleting, trigger: deleteSpeaker } = useDraftState(api.deleteSpeaker, {
  withoutData: true,
  statePath: ['speakers'],
  draftStateValue: speakers,
});

const triggerDelete = async (speakerId) => {
  if (locked.value) return;

  deletedIds.value.push(speakerId);

  await deleteSpeaker({ key: 'speaker_id', speakerId });
};

const deleteDisabled = (speakerId) => deleting.value && deletedIds.value.some((id) => id === speakerId);

const openSettings = () => {
  settingsOpen.value = true;
};

watch(speakersState, () => {
  deletedIds.value = deletedIds.value.filter((speakerId) =>
    speakersState.value.some((speaker) => speaker.speaker_id === speakerId),
  );
});
</script>
