import useDataStore from '@/stores/data';
import { isArray } from '@slideslive/fuse-kit/utils';
import { storeToRefs } from 'pinia';
import { computed, readonly, ref, unref } from 'vue';

// @param statePath is used to get to the nested value inside the draft state (and mock data)
// @param statePathToClear is used to clear the nested value inside the draft state (and mock data) if different from
// statePath
// @param draftStateValue sets draft state value if different from what is passed to request, eg. during delete
// @withoutData is used to trigger the query without passing any data, eg. during delete
function useDraftState(
  queryFn,
  { withoutData = false, statePath = null, statePathToClear = null, draftStateValue = null } = {},
) {
  const dataStore = useDataStore();
  const { updateDraftState, scheduleDraftClear } = dataStore;
  const { clearFromDraftOnPolling } = storeToRefs(dataStore);

  const statePathAsString = (statePathToClear || statePath)?.join?.('.');
  const triggered = ref(false);
  const loading = computed(
    () =>
      triggered.value ||
      clearFromDraftOnPolling.value.some((k) => {
        const kAsString = isArray(k) ? k.join('.') : k;

        return kAsString === statePathAsString;
      }),
  );

  // @param data is the data to be sent to the server
  // @param options is the options object to be passed to the queryFn, usually containst 'key' and '[key]' for
  // replacing item ID ({ key: 'speaker_id', speakerId: 123 })
  const trigger = async (...args) => {
    if (!unref(queryFn)) return;

    let data;
    let options;

    if (withoutData) {
      [options] = args;
    } else {
      [data, options] = args;
    }

    triggered.value = true;

    updateDraftState(statePath, unref(draftStateValue) || data);

    if (withoutData) {
      await unref(queryFn)({ ...options, statePath });
    } else {
      await unref(queryFn)(data, { ...options, statePath });
    }

    scheduleDraftClear(statePathToClear || statePath);

    triggered.value = false;
  };

  return {
    loading: readonly(loading),
    trigger,
  };
}

export default useDraftState;
