<template>
  <TabPanel class="tw-grid tw-grid-cols-2 tw-gap-x-10 tw-gap-y-5">
    <SettingsRange
      v-model="audioInputVolume"
      :disabled="locked"
      :min="audioInputVolumeState.min"
      :max="audioInputVolumeState.max"
      :step="audioInputVolumeState.step"
      :label="$t('settings_tabs.audio.audio_input_volume')"
    />

    <FormField
      v-model="audioInputMode"
      :disabled="locked"
      type="select"
      :label="$t('settings_tabs.audio.audio_monitor_mix')"
    >
      <option v-for="(option, index) in audioInputModeState.options" :key="option" :value="index">
        {{ option }}
      </option>
    </FormField>

    <div class="tw-flex tw-gap-5">
      <FormField
        v-model="audioMonitorMix"
        :disabled="locked"
        type="select"
        :label="$t('settings_tabs.audio.audio_monitor_mix')"
        class="tw-flex-1"
      >
        <option v-for="{ value, label } in audioMonitorMixOptions" :key="value" :value="value">{{ label }}</option>
      </FormField>

      <CircularButton
        :disabled="locked"
        :icon="audioStreamIcon"
        color="transparentWhite"
        size="large"
        shape="rounded"
        @click="toggleAudioStream"
      />
    </div>
  </TabPanel>
</template>

<script setup>
import useAppStore from '@/stores/app';
import useDataStore from '@/stores/data';
import { CircularButton, FormField, TabPanel } from '@slideslive/fuse-kit/vue';
import { storeToRefs } from 'pinia';
import { computed, ref, toRefs } from 'vue';

import SettingsRange from './SettingsRange.vue';

const appStore = useAppStore();
const { locked } = storeToRefs(appStore);
const dataStore = useDataStore();
const { cameras } = toRefs(dataStore.state);
const { main: mainCameraState } = toRefs(cameras.value);
const { audio_input_volume: audioInputVolumeState, audio_input_mode: audioInputModeState } = toRefs(
  mainCameraState.value,
);

const audioMonitorMixOptions = [
  {
    value: 'LR',
    label: 'L + R',
  },
  {
    value: 'LL',
    label: 'L + L',
  },
  {
    value: 'RR',
    label: 'R + R',
  },
  {
    value: 'mono',
    label: 'mono',
  },
];

const audioInputVolume = ref(audioInputVolumeState.value.value);
const audioMonitorMix = ref(audioMonitorMixOptions[0].value);
const audioInputMode = ref(audioInputModeState.value.selected_index);
const audioStream = ref(false);
const audioStreamIcon = computed(() => (audioStream.value ? 'volume-up' : 'volume-off'));

const toggleAudioStream = () => {
  audioStream.value = !audioStream.value;
};
</script>
