<template>
  <ControlSection>
    <Checkbox
      v-model="controlVMix"
      type="switch"
      :disabled="locked"
      :right-label="$t('auto_cut.label')"
      :message="$t('auto_cut.message')"
    />

    <ValueSwitcher v-model="selectedCamera" :disabled="locked" :options="cameraOptions" />
  </ControlSection>
</template>

<script setup>
import useAppStore from '@/stores/app';
import useDataStore from '@/stores/data';
import { Checkbox, ValueSwitcher } from '@slideslive/fuse-kit/vue';
import { storeToRefs } from 'pinia';
import { computed, ref, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';

import ControlSection from './ControlSection.vue';

const { t } = useI18n();
const appStore = useAppStore();
const { locked } = storeToRefs(appStore);
const dataStore = useDataStore();
const { switcher } = toRefs(dataStore.state);
const { control_vmix: controlVMixState, selected_camera: selectedCameraState } = toRefs(switcher.value);

const controlVMix = ref(controlVMixState.value);
const selectedCamera = ref(selectedCameraState.value.value);
const cameraOptions = computed(() =>
  selectedCameraState.value.options
    .map((number) => {
      const label = number === 0 ? t('camera_switch.wideshot') : t('camera_switch.cam', { number });

      return {
        value: number,
        label,
      };
    })
    // Make the Wideshot last
    .sort((a, b) => {
      if (a.value === 0) return 1;
      if (b.value === 0) return -1;

      return a.value - b.value;
    }),
);
</script>
