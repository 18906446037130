import useDataStore from '@/stores/data';
import { defineStore } from 'pinia';
import { computed, readonly, ref, toRefs, unref } from 'vue';

const useAppStore = defineStore('app', () => {
  const dataStore = useDataStore();
  const { controller } = toRefs(dataStore.state);
  const { control_mode: controlMode } = toRefs(controller.value);

  const locked = ref(false);
  const watcherControlAction = ref('move_pan_tilt');
  const trackerControlAction = ref('add_speaker');
  const tooltips = ref(true);
  const previewInfo = ref(true);
  const resourcesUsage = ref(false);
  const numberOfVisibleSpeakers = ref(10);
  const activeSpeakersTimeWindow = ref(600);

  const controlAction = computed({
    get() {
      if (controlMode.value === 'watcher') return watcherControlAction.value;
      if (controlMode.value === 'tracker') return trackerControlAction.value;

      return null;
    },
    set(value) {
      if (controlMode.value === 'watcher') {
        watcherControlAction.value = value;
      } else if (controlMode.value === 'tracker') {
        trackerControlAction.value = value;
      }
    },
  });

  const setControlAction = (value) => (controlAction.value = unref(value));
  const setTooltips = (value) => (tooltips.value = unref(value));
  const setPreviewInfo = (value) => (previewInfo.value = unref(value));
  const setResourcesUsage = (value) => (resourcesUsage.value = unref(value));
  const setNumberOfVisibleSpeakers = (value) => (numberOfVisibleSpeakers.value = unref(value));
  const setActiveSpeakersTimeWindow = (value) => (activeSpeakersTimeWindow.value = unref(value));
  const setLocked = (value) => (locked.value = unref(value));

  return {
    locked: readonly(locked),
    controlAction: readonly(controlAction),
    tooltips: readonly(tooltips),
    previewInfo: readonly(previewInfo),
    resourcesUsage: readonly(resourcesUsage),
    numberOfVisibleSpeakers: readonly(numberOfVisibleSpeakers),
    activeSpeakersTimeWindow: readonly(activeSpeakersTimeWindow),

    setLocked,
    setControlAction,
    setTooltips,
    setPreviewInfo,
    setResourcesUsage,
    setNumberOfVisibleSpeakers,
    setActiveSpeakersTimeWindow,
  };
});

export default useAppStore;
