<template>
  <Teleport to="body">
    <Modal ref="modal" v-model="show" @opening="reset" @close="reset">
      <ModalContent size="large">
        <template #header>
          <Heading level="h2" level-class="h3">{{ $t('recorders_modal.title') }}</Heading>
        </template>

        <template #footer="{ reject }">
          <Button color="transparent" outlined @click="reject">{{ $t('cancel') }}</Button>
          <Button :disabled="locked" type="submit" form="recorders-form">{{ $t('save') }}</Button>
        </template>

        <Form id="recorders-form" class="tw-space-y-5" @submit="save">
          <div class="tw-grid tw-grid-cols-1 tw-gap-5 sm:tw-grid-cols-2">
            <Checkbox
              v-model="recorderIndicator"
              type="switch"
              :disabled="locked"
              :right-label="$t('recorders_modal.recorder_indicator.label')"
            />
          </div>

          <Table>
            <TableHeader>
              <TableRow class="tw-font-bold">
                <TableCell>{{ $t('recorders_modal.device_type.title') }}</TableCell>
                <TableCell>{{ $t('recorders_modal.recorder_source.title') }}</TableCell>
                <TableCell>{{ $t('recorders_modal.recorder_name.title') }}</TableCell>
                <TableCell class="tw-w-20">{{ $t('recorders_modal.actions.title') }}</TableCell>
              </TableRow>
            </TableHeader>

            <RecordersEditItem
              v-for="(recorder, index) in recorders"
              :key="recorder.recorder_id"
              ref="items"
              :recorder
              :index
              @delete="deleteRecorder"
            />
          </Table>

          <Button :disabled="locked" color="transparentWhite" @click="addRecorder">
            <Icon name="plus" class="tw-mr-2 tw-size-6 tw-fill-current" />
            {{ $t('recorders_modal.add_recorder') }}
          </Button>
        </Form>
      </ModalContent>
    </Modal>
  </Teleport>
</template>

<script setup>
import api from '@/api';
import useDraftState from '@/composables/draftState';
import useAppStore from '@/stores/app';
import useDataStore from '@/stores/data';
import setIfChanged from '@/utils/setIfChanged';
import { deepEqual, generateRandomId } from '@slideslive/fuse-kit/utils';
import {
  Button,
  Checkbox,
  Form,
  Heading,
  Icon,
  Modal,
  ModalContent,
  Table,
  TableCell,
  TableHeader,
  TableRow,
} from '@slideslive/fuse-kit/vue';
import { ref, toRefs, useTemplateRef, watch } from 'vue';

import RecordersEditItem from './RecordersEditItem.vue';

const appStore = useAppStore();
const { locked } = toRefs(appStore);
const dataStore = useDataStore();
const { recorders: recordersState, recorders_config: recordersConfig } = toRefs(dataStore.state);
const { device_types: deviceTypesState, recorder_indicator_on: recorderIndicatorOnState } = toRefs(
  recordersConfig.value,
);

const show = defineModel();
const modal = ref(null);
const itemRefs = useTemplateRef('items');
const recorderIndicator = ref(recorderIndicatorOnState.value);
const recorders = ref(recordersState.value);

const { trigger: setState } = useDraftState(api.patchState, { statePathToClear: ['recorders_config'] });
const { trigger: setRecorders } = useDraftState(api.patchRecorders, { statePath: ['recorders'] });

const addRecorder = () => {
  if (locked.value) return;

  recorders.value = [
    ...recorders.value,
    {
      recorder_id: generateRandomId(20),
      recorder_name: '',
      device_type: deviceTypesState.value[0],
      ip_address: '',
      error: null,
      interface_url: null,
      message: '',
      removable: true,
      status: '',
    },
  ];
};

const deleteRecorder = (recorderId) => {
  if (locked.value) return;

  recorders.value = recorders.value.filter((recorder) => recorder.recorder_id !== recorderId);
};

const save = async () => {
  if (locked.value) return;

  const statePatch = { recorders_config: {} };
  const recordersPatch = itemRefs.value
    .map((itemRef) => (!itemRef.deleted.value && itemRef.isValid() ? itemRef.getData() : null))
    .filter((itemRef) => !!itemRef);
  const recordersChanged =
    recordersPatch.some(
      (recorder) =>
        !deepEqual(
          recorder,
          recordersState.value.find((r) => r.recorder_id === recorder.recorder_id),
        ),
    ) || recordersPatch.length !== recordersState.value;

  setIfChanged(statePatch.recorders_config, 'recorder_indicator_on', recorderIndicator.value);

  if (Object.keys(statePatch.recorders_config).length) {
    setState(statePatch);
  }

  if (recordersChanged) {
    setRecorders(recordersPatch);
  }

  modal.value.accept();
};

const resetRecorders = () => {
  recorders.value = recordersState.value;

  for (const itemRef of itemRefs.value) {
    itemRef.reset();
  }
};

const reset = () => {
  recorderIndicator.value = recorderIndicatorOnState.value;

  resetRecorders();
};

watch(recordersState, (newRecorders, oldRecorders) => {
  if (!deepEqual(newRecorders, oldRecorders)) {
    resetRecorders();
  }
});
</script>
