<template>
  <Teleport to="body">
    <Modal ref="modal" v-model="show" @opening="reset" @close="reset">
      <ModalContent size="large">
        <template #header>
          <Heading level="h2" level-class="h3">{{ $t('speakers_modal.title') }}</Heading>
        </template>

        <template #footer="{ reject }">
          <Button color="transparent" outlined @click="reject">{{ $t('cancel') }}</Button>
          <Button :disabled="locked" type="submit" form="speakers-form">{{ $t('save') }}</Button>
        </template>

        <div class="tw-space-y-5">
          <Heading level="h3" level-class="h4">{{ $t('speakers_modal.active_speakers') }}</Heading>

          <ol v-if="sortedActiveSpeakers.length" class="tw-grid tw-grid-cols-5 tw-gap-2 md:tw-grid-cols-10">
            <Speaker
              v-for="speaker in sortedActiveSpeakers"
              :key="speaker.speaker_id"
              tag="li"
              :speaker
              @delete="markDeletedSingle"
            />
          </ol>

          <Text v-else>{{ $t('speakers_modal.no_active_speakers') }}</Text>

          <Heading level="h3" level-class="h4">{{ $t('speakers_modal.inactive_speakers') }}</Heading>

          <ol v-if="sortedInactiveSpeakers.length" class="tw-grid tw-grid-cols-5 tw-gap-2 md:tw-grid-cols-10">
            <Speaker
              v-for="speaker in sortedInactiveSpeakers"
              :key="speaker.speaker_id"
              tag="li"
              :speaker
              @delete="markDeletedSingle"
            />
          </ol>

          <Text v-else>{{ $t('speakers_modal.no_inactive_speakers') }}</Text>

          <div class="tw-flex tw-flex-wrap tw-items-start tw-gap-2">
            <Button :disabled="locked" color="semiTransparentWhite" @click="markDeletedInactive">
              {{ $t('speakers_modal.delete_inactive_speakers') }}
            </Button>

            <Button :disabled="locked" color="semiTransparentWhite" @click="markDeletedAll">
              {{ $t('speakers_modal.delete_all_speakers') }}
            </Button>
          </div>

          <Form id="speakers-form" class="tw-grid tw-grid-cols-1 tw-gap-5 sm:tw-grid-cols-2" @submit="save">
            <FormField
              v-model="activeSpeakersTimeWindow"
              type="number"
              :disabled="locked"
              min="0"
              :label="$t('speakers_modal.active_time_window.label')"
            />
          </Form>
        </div>
      </ModalContent>
    </Modal>
  </Teleport>
</template>

<script setup>
import api from '@/api';
import useDraftState from '@/composables/draftState';
import useAppStore from '@/stores/app';
import useDataStore from '@/stores/data';
import setIfChanged from '@/utils/setIfChanged';
import { capitalizeFirstLetter } from '@slideslive/fuse-kit/utils';
import { Button, Form, FormField, Heading, Modal, ModalContent, Text } from '@slideslive/fuse-kit/vue';
import { computed, ref, toRefs, unref } from 'vue';

import Speaker from './Speaker.vue';

const appStore = useAppStore();
const { locked, activeSpeakersTimeWindow: activeSpeakersTimeWindowStore } = toRefs(appStore);
const dataStore = useDataStore();
const { speakers: speakersState } = toRefs(dataStore.state);

const show = defineModel();
const modal = ref(null);
const activeSpeakersTimeWindow = ref(activeSpeakersTimeWindowStore.value);
const deletedIds = ref([]);

const speakers = computed(() =>
  speakersState.value.filter((speaker) => !deletedIds.value.includes(speaker.speaker_id)),
);
const activeSpeakers = computed(() =>
  speakers.value.filter((speaker) => speaker.detection_age_seconds <= activeSpeakersTimeWindow.value),
);
const inactiveSpeakers = computed(() =>
  speakers.value.filter((speaker) => speaker.detection_age_seconds > activeSpeakersTimeWindow.value),
);
const sortedActiveSpeakers = computed(() => activeSpeakers.value.slice().sort((a, b) => a.x_pos - b.x_pos));
const sortedInactiveSpeakers = computed(() => inactiveSpeakers.value.slice().sort((a, b) => a.x_pos - b.x_pos));

const { trigger: setState } = useDraftState(api.patchState, { statePathToClear: ['speakers'] });

const markDeletedSingle = (speakerId) => {
  if (locked.value) return;

  deletedIds.value.push(speakerId);
};

const markDeletedInactive = () => {
  if (locked.value) return;

  deletedIds.value.push(...inactiveSpeakers.value.map((speaker) => speaker.speaker_id));
};

const markDeletedAll = () => {
  if (locked.value) return;

  deletedIds.value = speakersState.value.map((speaker) => speaker.speaker_id);
};

const save = () => {
  if (locked.value) return;

  const settingsPatch = {};

  setIfChanged(settingsPatch, 'activeSpeakersTimeWindow', activeSpeakersTimeWindowStore, activeSpeakersTimeWindow);

  for (const key of Object.keys(settingsPatch)) {
    appStore[`set${capitalizeFirstLetter(key)}`](unref(settingsPatch[key]));
  }

  if (deletedIds.value.length) {
    const statePatch = {
      speakers: speakersState.value.filter((speaker) => !deletedIds.value.includes(speaker.speaker_id)),
    };

    setState(statePatch);
  }

  modal.value.accept();
};

const reset = () => {
  deletedIds.value = [];
  activeSpeakersTimeWindow.value = activeSpeakersTimeWindowStore.value;
};
</script>
