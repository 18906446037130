<template>
  <TabPanel class="tw-flex tw-gap-x-10">
    <div class="tw-space-y-5">
      <SettingsRange
        v-model="captureDelay"
        :disabled="locked"
        :min="captureDelayState.min"
        :max="captureDelayState.max"
        :step="captureDelayState.step"
        :label="$t('settings_tabs.dev.capture_delay_ms')"
      />

      <Button color="semiTransparentWhite" block>
        <Icon name="download" class="tw-mr-2 tw-size-6 tw-fill-current" />
        {{ $t('settings_tabs.dev.download_data_from_current_process') }}
      </Button>

      <Button color="semiTransparentWhite" block>
        <Icon name="download" class="tw-mr-2 tw-size-6 tw-fill-current" />
        {{ $t('settings_tabs.dev.download_data_from_all_processes') }}
      </Button>
    </div>

    <div class="tw-flex-1 tw-space-y-5">
      <div class="tw-space-y-2">
        <Checkbox v-model="showDebug" :disabled="locked" :right-label="$t('settings_tabs.dev.show_debug')" />
        <Checkbox
          v-model="showCalibrationGrid"
          :disabled="locked"
          :right-label="$t('settings_tabs.dev.show_calibration_grid')"
        />
      </div>

      <div class="tw-flex tw-flex-1 tw-items-end tw-justify-between tw-gap-5">
        <div class="tw-space-y-5">
          <Text size="large">
            <Link scheme="underline" :href="cameraLogUrl('error')" target="_blank" download>
              {{ $t('settings_tabs.dev.camera_error_log') }}
            </Link>
          </Text>

          <Text size="large">
            <Link scheme="underline" href="/state" target="_blank" download>
              {{ $t('settings_tabs.dev.conf_data_dev') }}
            </Link>
          </Text>

          <Text size="large">
            <Link scheme="underline" :href="cameraLogUrl('system')" target="_blank" download>
              {{ $t('settings_tabs.dev.camera_system_log') }}
            </Link>
          </Text>
        </div>

        <Button color="semiTransparentWhite" block @click="showOther">
          {{ $t('other') }}
        </Button>
      </div>
    </div>
  </TabPanel>
</template>

<script setup>
import useAppStore from '@/stores/app';
import useDataStore from '@/stores/data';
import { Button, Checkbox, Icon, Link, TabPanel, Text } from '@slideslive/fuse-kit/vue';
import { storeToRefs } from 'pinia';
import { ref, toRefs } from 'vue';

import SettingsRange from './SettingsRange.vue';

const appStore = useAppStore();
const { locked } = storeToRefs(appStore);
const dataStore = useDataStore();
const { cameras, controller, app } = toRefs(dataStore.state);
const { main: mainCameraState } = toRefs(cameras.value);
const { capture_delay: captureDelayState } = toRefs(mainCameraState.value);
const { show_calibration_grid: showCalibrationGridState } = toRefs(controller.value);
const { debug_mode: showDebugState, main_cam_url: mainCamUrl } = toRefs(app.value);

const captureDelay = ref(captureDelayState.value.value);
const showDebug = ref(showDebugState.value);
const showCalibrationGrid = ref(showCalibrationGridState.value);

const cameraLogUrl = (kind) => `${mainCamUrl.value}/-wvdata-/FileData?filetype=log&kind=${kind}`;

const showOther = () => {
  console.log('showOther');
};
</script>
