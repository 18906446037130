<template>
  <TabPanel class="tw-grid tw-grid-cols-2 tw-gap-x-10 tw-gap-y-5">
    <SettingsRange
      v-model="resetProcessPanTiltSpeed"
      :disabled="locked"
      :min="resetProcessPanTiltSpeedState.min"
      :max="resetProcessPanTiltSpeedState.max"
      :step="resetProcessPanTiltSpeedState.step"
      :label="$t('settings_tabs.advanced.reset_process_pan_tilt_speed')"
    />

    <SettingsRange
      v-model="resetProcessZoomSpeed"
      :disabled="locked"
      :min="resetProcessZoomSpeedState.min"
      :max="resetProcessZoomSpeedState.max"
      :step="resetProcessZoomSpeedState.step"
      :label="$t('settings_tabs.advanced.reset_process_zoom_speed')"
    />

    <Checkbox
      v-model="autoPanelDiscussion"
      :disabled="locked"
      type="switch"
      :right-label="$t('settings_tabs.advanced.auto_panel_discussion')"
    />
  </TabPanel>
</template>

<script setup>
import useAppStore from '@/stores/app';
import useDataStore from '@/stores/data';
import { Checkbox, TabPanel } from '@slideslive/fuse-kit/vue';
import { storeToRefs } from 'pinia';
import { ref, toRefs } from 'vue';

import SettingsRange from './SettingsRange.vue';

const appStore = useAppStore();
const { locked } = storeToRefs(appStore);
const dataStore = useDataStore();
const { movement, controller } = toRefs(dataStore.state);
const {
  manual_move_pan_tilt_speed: resetProcessPanTiltSpeedState,
  manual_move_zoom_speed: resetProcessZoomSpeedState,
} = toRefs(movement.value);
const { auto_panel_discussion: autoPanelDiscussionState } = toRefs(controller.value);

const resetProcessPanTiltSpeed = ref(resetProcessPanTiltSpeedState.value.value);
const resetProcessZoomSpeed = ref(resetProcessZoomSpeedState.value.value);
const autoPanelDiscussion = ref(autoPanelDiscussionState.value);
</script>
