<template>
  <TabPanel v-bind="$attrs" class="tw-flex tw-h-full tw-items-start tw-gap-x-5">
    <div class="tw-flex tw-flex-col tw-space-y-5">
      <Button :disabled="locked" color="red" class="tw-whitespace-nowrap" @click="startAllRec">
        {{ $t('settings_tabs.recorders.start_all_rec') }}
      </Button>

      <Button :disabled="locked" color="transparentRed" outlined class="tw-whitespace-nowrap" @click="stopAllRec">
        {{ $t('settings_tabs.recorders.stop_all_rec') }}
      </Button>

      <Text size="large">
        <Link tag="button" scheme="underline" @click="openSettings">
          {{ $t('settings') }}
        </Link>
      </Text>
    </div>

    <div class="tw-max-h-full tw-overflow-auto">
      <Table>
        <TableRow v-for="recorder in recorders" :key="recorder.recorder_id" with-border>
          <TableCell class="tw-w-50">
            <div class="tw-flex tw-items-center tw-gap-2">
              <Text class="tw-font-black">{{ recorder.recorder_name }}</Text>
              <Icon v-if="recorder.error" name="warning" class="tw-size-4 tw-fill-current tw-text-state-warn" />
            </div>
          </TableCell>

          <TableCell class="tw-w-10">
            <CircularButton
              v-if="recorder.status === 'recording'"
              :disabled="locked"
              shape="rounded"
              size="xsmall"
              color="red"
              @click="toggleRecorder(recorder)"
            >
              <div class="tw-size-3 tw-bg-red-dark"></div>
            </CircularButton>

            <CircularButton
              v-else-if="recorder.status === 'stand-by'"
              :disabled="locked"
              size="xsmall"
              color="red"
              @click="toggleRecorder(recorder)"
            >
              <div class="tw-size-3 tw-rounded-full tw-bg-red-dark"></div>
            </CircularButton>
          </TableCell>

          <TableCell>
            <Text size="small">
              <span :class="['tw-font-bold', recorderStatusColor(recorder)]">{{ recorder.status }}</span
              ><template v-if="recorder.message">, {{ recorder.message }}</template>
            </Text>
          </TableCell>

          <TableCell class="tw-w-12">
            <CircularButton
              v-if="recorder.interface_url"
              icon="double-arrow"
              size="xsmall"
              color="transparentWhite"
              tag="a"
              :href="recorder.interface_url"
              target="_blank"
            />
          </TableCell>
        </TableRow>
      </Table>
    </div>
  </TabPanel>

  <RecordersModal v-model="settingsOpen" />
</template>

<script setup>
import useAppStore from '@/stores/app';
import useDataStore from '@/stores/data';
import {
  Button,
  CircularButton,
  Icon,
  Link,
  TabPanel,
  Table,
  TableCell,
  TableRow,
  Text,
} from '@slideslive/fuse-kit/vue';
import { storeToRefs } from 'pinia';
import { ref, toRefs } from 'vue';

import RecordersModal from './RecordersModal.vue';

const appStore = useAppStore();
const { locked } = storeToRefs(appStore);
const dataStore = useDataStore();
const { recorders } = toRefs(dataStore.state);
const settingsOpen = ref(false);

const recorderStatusColor = ({ status }) => {
  if (status === 'recording') {
    return 'tw-text-state-err';
  }

  if (status === 'stand-by') {
    return 'tw-text-state-succ';
  }

  return 'tw-text-white/50';
};

const startAllRec = () => {
  console.log('startAllRec');
};

const stopAllRec = () => {
  console.log('stopAllRec');
};

const toggleRecorder = (recorder) => {
  console.log('toggleRecorder', recorder);
};

const openSettings = () => {
  settingsOpen.value = true;
};
</script>
